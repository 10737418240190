import { defineStore } from 'pinia';

let cust = JSON.parse(localStorage.getItem('customer'));
if(cust == null) {
  cust = {
    name:'',
    email:'',
    isLoggedIn:false,
    subscription: {
      isExpired: true,
      expiresAt: '',
    }
  };
}

export const useStore = defineStore('store', {
  state: () => ({
    customer: cust,
  }),
  actions: {
    storeCustomer(customer) {
      console.log('Store.js', customer);
      this.customer = customer;
      localStorage.setItem('customer', JSON.stringify(customer));
    },
    logout() {
      let customer = {
        id:0,
        name:'',
        email:'',
        isLoggedIn:false,
        isExpired: true,
        expiresAt: '',
      };
      this.customer = customer;
      localStorage.setItem('customer', JSON.stringify(customer));
    },
  },
});