<template>

  <v-col cols="12" md="6" lg="4">
    <v-sheet class="rounded-lg">
      <div class="image-wrapper">
        <img :src="image || placeholderImage" alt="Event Image" class="image rounded-t-lg">
      </div>
      <div class="content">
        <p class="date">{{ date }}</p>
        <h3 class="title">{{ title }}</h3>
        <p class="text">{{ text }}</p>
        <v-btn
          @click="redirectToUrl"
          class="bg-amber-darken-1 ml-auto"
          rounded
          variant="flat"
        >
          Leia mais
        </v-btn>
      </div>
    </v-sheet>
  </v-col>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';

const props = defineProps({
    image: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    text: {
        type: String,
        required: true,
    },
    date: {
        type: String,
        required: true,
    },
    slug: {
        type: String,
        required: true,
    },
});
const placeholderImage = require('@/assets/whiteLogo.svg');
const { xs } = useDisplay();
const isMobile = xs.value;
const router = useRouter();

const redirectToUrl = () => {
  router.push('/noticias/' + props.slug);
}

</script>

<style scoped lang="scss">

.image-wrapper {
  display: flex;
  width:100%;
  /*height: 250px;*/
}
/*
@media (max-width: 1904px) {
  .image-wrapper {
    height: 150px;
  }
}
*/
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
}

.title {
  font-size: 22px;
  text-align: left;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text {
  color: grey;
  font-size: 14px;
  text-align: left;
  margin-bottom: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.date {
    font-size: 14px;
    text-align: left;
    color: gray;
    margin-bottom: 10px;
}
</style>
