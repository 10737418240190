<template>
    <div>
        <Navbar />
        <router-view />
        <div class="footer-filler">
            
        </div>
        
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Navbar,
        Footer,
    },
}
</script>

<style scoped lang="scss">
    .footer-filler {
        min-height: 400px;
        @media (max-width: 1220px) {
            min-height: 550px;
        }
        @media (max-width: 767px) {
            min-height: 530px;
        } 
    }
</style>
