<template>

<v-col cols="12">
  <v-sheet class="rounded-lg">
      
      <img class="promo-banner rounded-t-lg mb-5" :src="xs ? promocao.image2 : promocao.image1" :alt="promocao.title">

      <h2 class="text-center mb-4">{{ promocao.title }}</h2>

      <v-expansion-panels>
          <v-expansion-panel>
              <v-expansion-panel-title>

                <div class="text-center justify-center w-100">
                  <v-btn
                  class="bg-amber-darken-1 my-5"
                  rounded
                  variant="flat"
                  >
                    <v-icon class="mr-1">mdi-magnify</v-icon>
                    Clique aqui e saiba mais
                  </v-btn>
                </div>

              </v-expansion-panel-title>
              <v-expansion-panel-text>
                  <p class="text-center text-black my-4">
                    <v-icon v-if="promocao.has_ended" class="mr-1">mdi-alarm-off</v-icon>
                    <v-icon v-else class="mr-1">mdi-alarm</v-icon>
                    Esta promoção {{ promocao.has_ended ? 'encerrou' : 'encerra'}} em {{ promocao.ends_at }}
                  </p>

                  <v-divider></v-divider>

                  <div class="promo-texto pa-5" v-html="promocao.text"></div>

                  <v-divider></v-divider>

                  <div v-if="promocao.has_ended" class="py-4">
                    <div v-if="promocao.was_drawn && promocao.winners.length > 0">
                      <p class="text-center">Os ganhadores foram:</p>
                      <p class="text-center">
                        <span v-for="(winner, index) of promocao.winners" :key="index">{{ winner }}<br /></span>
                      </p>
                    </div>
                    <p class="text-center" v-else>Em breve divulgaremos os ganhadores</p>
                  </div>
                  <div v-else-if="store.customer.isLoggedIn && !store.customer.isExpired" class="py-4 text-center">
                    <v-btn
                      v-if="!participantes.includes(store.customer.id)"
                      @click="participar(promocao.uuid)"
                      :disabled="isJoining"
                      class="bg-amber-darken-1 align-self-center"
                      rounded
                      variant="flat"
                    >
                      <v-progress-circular v-if="isJoining" indeterminate color="black" class="align-self-center mr-2"></v-progress-circular>
                      QUERO PARTICIPAR
                    </v-btn>
                    <p v-else class="text-center text-green">
                      <v-icon class="mr-1">mdi-check-bold</v-icon>
                      Você está participando desta promoção!
                    </p>
                  </div>
                  <p v-else class="text-center py-4">Associe-se ou faça login para participar!</p>
              </v-expansion-panel-text>
          </v-expansion-panel>
      </v-expansion-panels>

  </v-sheet>
</v-col>

</template>

<script setup>
import { inject, ref } from 'vue';
import { useStore } from '@/stores';
import { useDisplay } from 'vuetify';

const axios = inject('axios');
const backendUrl = inject('backendUrl');
const store = useStore();
const { xs } = useDisplay();
const isJoining = ref(false);

const props = defineProps({
  promocao: {
    type: Object,
    default: {},
  },
});

const participantes = ref(props.promocao.participants_ids);

const participar = (uuid) => {
  console.log(uuid);
  if(participantes.value.includes(store.customer.id)) {
    alert('Você já está participando desta promoção');
    return false;
  }
  isJoining.value = true;
  axios.get(
    backendUrl + `/promotion/join/` + uuid,
  ).then((response) => {
    isJoining.value = false;
    if(response.data.success) {
      participantes.value.push(store.customer.id);
    } else {
      alert(response.data.error);
    }
  });
}


</script>

<style lang="scss">
.promo-banner {
    width: 100%;
    height: auto;
}
.promo-texto {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    h2 {
        text-align: left;
    }
    .data {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 20px;
    }
    .banner {
        width:100%;
        margin-bottom: 20px;
        border-radius: 15px;
    }
    p, li {
        font-weight: 400;
    }
    ol, ul {
        padding-left: 30px;
    }
}

</style>

