<template lang="html">
  <div class="cadastro">

    <div class="flex-row">

      <v-text-field
      label="CEP"
      variant="outlined"
      :error-messages="cepErrors"
      @blur="validateCep"
      v-model="cep"
      v-maska:[cepMask]
      class="mb-2"
      ></v-text-field>

      <v-select
      label="Estado"
      :items="states"
      variant="outlined"
      :error-messages="stateErrors"
      @blur="validateState"
      v-model="state"
      class="mb-2"
      ></v-select>

      <v-select
      label="Cidade"
      :items="towns"
      item-title="name"
      item-value="id"
      variant="outlined"
      :error-messages="townErrors"
      @blur="validateTown"
      v-model="town"
      class="mb-2"
      ></v-select>

    </div>

    <v-text-field
    label="Logradouro"
    variant="outlined"
    :error-messages="streetErrors"
    @blur="validateStreet"
    v-model="street"
    class="mb-2"
    ></v-text-field>

    <div class="flex-row">
    
      <v-text-field
      label="Número"
      variant="outlined"
      :error-messages="numberErrors"
      @blur="validateNumber"
      v-model="number"
      class="mb-2"
      ></v-text-field>

      <v-text-field
      label="Complemento"
      variant="outlined"
      v-model="complement"
      class="mb-2"
      ></v-text-field>

      <v-text-field
      label="Bairro"
      variant="outlined"
      :error-messages="districtErrors"
      @blur="validateDistrict"
      v-model="district"
      class="mb-2"
      ></v-text-field>

    </div>

    <div class="d-flex">

      <v-btn
        v-if="action == 'update'"
        @click="closeDialog"
        class="bg-amber-darken-1 mr-auto"
        rounded
        variant="flat"
      >
        Fechar
      </v-btn>

      <v-btn
        @click="sendForm"
        :disabled="isSending"
        class="bg-amber-darken-1 ml-auto"
        rounded
        variant="flat"
      >
        <v-progress-circular v-if="isSending" indeterminate color="black" class="align-self-center mr-2"></v-progress-circular>
        {{ action == 'create' ? 'Próximo' : 'Atualizar' }}
      </v-btn>

    </div>

  </div>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue';
import { vMaska } from "maska";

const axios = inject('axios');
const backendUrl = inject('backendUrl');
const props = defineProps({
  action: {
    type: String,
    default: 'create',
  },
  dados: {
    type: Object,
    default: {
      cep: null,
      state: null,
      town: null,
      street: null,
      number: null,
      complement: null,
      district: null
    },
  },
});
const emit = defineEmits(["send-form", "close-dialog"]);
const isSending = ref(false);
let deveBuscarCep = false;

//fecha dialog
const closeDialog = () => {
  emit("close-dialog");
}

//retorna os dados do form para o parent
const sendForm = () => {
  isSending.value = true;
  let cepValido = validateCep();
  let estadoValido = validateState();
  let cidadeValida = validateTown();
  let logradouroValido = validateStreet();
  let numeroValido = validateNumber();
  let bairroValido = validateDistrict();
  if(
    !cepValido ||
    !estadoValido || 
    !cidadeValida ||
    !logradouroValido ||
    !numeroValido ||
    !bairroValido
  ) {
    isSending.value = false;
    return false;
  }
  emit(
    "send-form",
    {
      cep: cep.value.replace(/\D/g,''),
      state: state.value,
      town: town.value,
      street: street.value,
      number: number.value,
      complement: complement.value,
      district: district.value
    }
  );
};

//cep
const cep = ref(props.dados.cep);
const cepErrors = ref([]);
const cepMask = { mask: '##.###-###' };
watch(cep, (value) => {
  if(value.length == 10) {
    if(deveBuscarCep) {
      buscaDadosCep();
    }
  }
  deveBuscarCep = true;
})
const buscaDadosCep = () => {
  if(cep.value == '') return false;
  let cepValue = cep.value.replace(/\D/g,'');
  axios.get(backendUrl + '/get-cep-data/' + cepValue)
  .then((response) => {
    if(!response.data.erro) {
      selectedTown.value = parseInt(response.data.ibge);
      if(state.value == response.data.uf) {
        buscaCidades();
      } else {
        state.value = response.data.uf;
      }
      district.value = response.data.bairro;
      street.value = response.data.logradouro;
    }
  });
}
const validateCep = () => {
  cepErrors.value = [];
  if(cep.value === null || cep.value.trim() == '') {
    cepErrors.value.push('O CEP é obrigatório');
    return false;
  }
  return true;
};

//estado
const state = ref(props.dados.state);
const stateErrors = ref([]);
const validateState = () => {
  stateErrors.value = [];
  if(state.value === null || state.value.trim() == '') {
    stateErrors.value.push('O estado é obrigatório');
    return false;
  }
  return true;
};
watch(state, (value) => {
  town.value = null;
  buscaCidades();
})
const states = ref([
  {value: "AC", title: "Acre"},
  {value: "AL", title: "Alagoas"},
  {value: "AP", title: "Amapá"},
  {value: "AM", title: "Amazonas"},
  {value: "BA", title: "Bahia"},
  {value: "CE", title: "Ceará"},
  {value: "DF", title: "Distrito Federal"},
  {value: "ES", title: "Espírito Santo"},
  {value: "GO", title: "Goiás"},
  {value: "MA", title: "Maranhão"},
  {value: "MT", title: "Mato Grosso"},
  {value: "MS", title: "Mato Grosso do Sul"},
  {value: "MG", title: "Minas Gerais"},
  {value: "PA", title: "Pará"},
  {value: "PB", title: "Paraíba"},
  {value: "PR", title: "Paraná"},
  {value: "PE", title: "Pernambuco"},
  {value: "PI", title: "Piauí"},
  {value: "RJ", title: "Rio de Janeiro"},
  {value: "RN", title: "Rio Grande do Norte"},
  {value: "RS", title: "Rio Grande do Sul"},
  {value: "RO", title: "Rondônia"},
  {value: "RR", title: "Roraima"},
  {value: "SC", title: "Santa Catarina"},
  {value: "SP", title: "São Paulo"},
  {value: "SE", title: "Sergipe"},
  {value: "TO", title: "Tocantins"},
  {value: "EX", title: "Estrangeiro"}
]);

//cidade
const selectedTown = ref(props.dados.town);
const town = ref(null);
const towns = ref([]);
const townErrors = ref([]);
const buscaCidades = () => {
  axios.get(backendUrl + '/get-state-towns/' + state.value)
  .then((response) => {
    towns.value = response.data;
    if(selectedTown.value !== null) {
      town.value = selectedTown.value;
      selectedTown.value = null;
    }
  });
}
const validateTown = () => {
  townErrors.value = [];
  console.log(town.value);
  if(town.value === null || town.value == '') {
    townErrors.value.push('A cidade é obrigatória');
    return false;
  }
  return true;
};

//rua
const street = ref(props.dados.street);
const streetErrors = ref([]);
const validateStreet = () => {
  streetErrors.value = [];
  if(street.value === null || street.value.trim() == '') {
    streetErrors.value.push('O logradouro é obrigatório');
    return false;
  }
  return true;
};

//número
const number = ref(props.dados.number);
const numberErrors = ref([]);
const validateNumber = () => {
  numberErrors.value = [];
  if(number.value === null || number.value.trim() == '') {
    numberErrors.value.push('O número é obrigatório');
    return false;
  }
  return true;
};

//complemento
const complement = ref(props.dados.complement);

//bairro
const district = ref(props.dados.district);
const districtErrors = ref([]);
const validateDistrict = () => {
  districtErrors.value = [];
  if(district.value === null || district.value.trim() == '') {
    districtErrors.value.push('O bairro é obrigatório');
    return false;
  }
  return true;
};

onMounted(() => {
  if(state != null) {
    buscaCidades();
  }
})

</script>

<style lang="scss" scoped>
.cadastro {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  .flex-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0 26px;
    > * {
        min-width: 300px;
        max-width: 608px;
    }
  }
  h2 {
    margin-bottom:20px
  }
  .botao {
    align-self: flex-end;
  }
}
</style>
