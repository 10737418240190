<template>
        
  <h1>Notícias</h1>

  <v-container class="pa-0 mx-0">
    <v-row class="mb-4">
      <v-col cols="12" lg="6">
        <v-text-field
          v-model="searchForm.term"
          id="termo"
          label="Termo de busca" 
          variant="outlined"
          bg-color="white"
          hide-details
          clearable
          clear-icon="mdi-close"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="5">
        <v-text-field 
          v-model="searchForm.date"
          id="data"
          label="Data mm/aaaa" 
          variant="outlined"
          bg-color="white"
          color="#03af9a"
          hide-details
          v-maska:[dateMask]
          clearable
          clear-icon="mdi-close"
        >
        </v-text-field>
      </v-col>
      <v-col align-self="center" cols="12" lg="1">
        <v-btn
          class="bg-amber-darken-1 w-100"
          rounded
          variant="flat"
          @click="fetchPosts"
        >
          BUSCAR
        </v-btn>
      </v-col>
    </v-row>
  </v-container>

  <v-container class="pa-0 mx-0">

    <v-row v-if="loadingPosts" class="text-center justify-center">
      <v-progress-circular indeterminate color="black" class="align-self-center my-4"></v-progress-circular>
    </v-row>

    <v-row v-else-if="noticias.length > 0" class="text-center">
      <CardNoticia
        v-for="(noticia, index) of noticias" :key="index"
        :id="noticia.id"
        :image="noticia.image2"
        :title="noticia.title"
        :date="noticia.updated"
        :text="noticia.text"
        :slug="noticia.slug"    
      />
    </v-row>

    <v-row v-else class="justify-center">
      <p class="text-center my-4">Não há notícias cadastradas</p>
    </v-row>


  </v-container>

</template>

<script setup>
    import { inject, onMounted, ref } from 'vue';
    import CardNoticia from '@/components/CardNoticia.vue';
    import { vMaska } from "maska";

    const axios = inject('axios');
    const backendUrl = inject('backendUrl');
    const csrfUrl = inject('csrfUrl');
    const noticias = ref([]);
    const loadingPosts = ref(true);
    const dateMask = { mask: '##/####' };

    //filtros
    let searchForm = ref({
      term: '',
      date: '',
    });

    //busca notícias
    const fetchPosts = async () => {
      loadingPosts.value = true;
      await axios.get(csrfUrl); 
      axios.post(
        backendUrl + '/posts',
        {filters: searchForm.value},
      ).then((response) => {
        loadingPosts.value = false;
        noticias.value = response.data;
      });
    }

    onMounted(() => {
      fetchPosts();
    })

</script>