<template>
<div class="wrapper">
    <h2>VANTAGENS</h2>
    <div class="section">
        <div v-for="item in items" :key="item.id" class="component">
            <img class="icon" :src="item.icon">{{ item.icon }}/>
            <h3 class="title">{{ item.title }}</h3>
            <p class="text">{{ item.text }}</p>
        </div>
    </div>
    <p class="text-caption">*Todos os benefícios podem variar de acordo com o local, a capacidade de lotação do evento e a disponibilidade do artista.</p>
</div>
</template>

<script setup>
import { inject, onMounted, ref } from 'vue';
import promo from '@/assets/promo.svg';
import camarim from '@/assets/camarim.svg';
import descontos from '@/assets/descontos.svg';
import pre_venda from '@/assets/pre_venda.svg';
import entrada_exclusiva from '@/assets/entrada_exclusiva.svg';

const axios = inject('axios');
const backendUrl = inject('backendUrl');

const items = ref([{
        id: 1,
        icon: descontos,
        title: '',
        text: '',
    },
    {
        id: 2,
        icon: entrada_exclusiva,
        title: '',
        text: '',
    },
    {
        id: 3,
        icon: camarim,
        title: '',
        text: '',
    },
    {
        id: 4,
        icon: promo,
        title: '',
        text: '',
    },
    {
        id: 5,
        icon: pre_venda,
        title: '',
        text: '',
    },
]);

onMounted(() => {
  axios.get(
    backendUrl + '/get-text/2' //busca textos das vantagens (id = 2)
  ).then((response) => {
    for(let i in items.value) {
        items.value[i]['title'] = response.data.content[i]['title'];
        items.value[i]['text'] = response.data.content[i]['text'];
    }
  });
})



</script>

<style scoped lang="scss">
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: black;

    h2 {
        color: white;
        margin-top: 2%;
    }
}

.section {
    max-width: 1480px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 2%;
}

.component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 400px;
    margin: 20px 0;
}

.icon {
    width: 70px;
    height: 70px;
    color: white;
}

.title {
    font-size: 18px;
    font-weight: bold;
    color: $verde-opus;
}

.text {
    margin-top: 5px;
    font-size: 14px;
    color: gray;
}
</style>
