<template>

  <v-col cols="12" md="6" lg="4">
    <v-sheet @click="redirectToUrl" class="rounded-lg">
      <div class="image-wrapper">
          <img :src="image || placeholderImage" alt="Event Image" class="image rounded-t-lg">
      </div>
      <div class="content">
          <p class="date">{{ date }}</p>
          <h3 class="title">{{ title }}</h3>
          <div class="button-wrapper">
              <button class="button">Compre Aqui</button>
          </div>
          <div class="details">
              <div class="left-side">
                  <v-icon 
                      v-if="!!icon" 
                      class="icon"
                  >
                      {{ icon }}
                  </v-icon>
                  <p 
                      v-else
                      class="place"
                  >
                      {{ place }}
                  </p>
              </div>
              <div class="right-side">
                  <p v-if="discount > 0" class="discount">{{ discount }}% Off</p>
              </div>
          </div>
      </div>
    </v-sheet>
  </v-col>
</template>

<script setup>
import { inject } from 'vue';
import { useStore } from '@/stores';

const axios = inject('axios');
const backendUrl = inject('backendUrl');
const store = useStore();

const props = defineProps({
    id: {
        type: Number,
        required: true,
    },
    image: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    date: {
        type: String,
        required: true,
    },
    icon: {
        type: String,
        default: '',
    },
    place: {
        type: String,
        default: '',
    },
    discount: {
        type: Number,
        default: 0,
    },
    url: {
        type: String,
        required: true,
    },
});
const placeholderImage = require('@/assets/whiteLogo.svg');

const redirectToUrl = () => {
    if(store.customer.isLoggedIn) {
        axios.get(backendUrl + '/event/click/' + props.id)
    }
    window.open(props.url, '_blank');
}
</script>

<style scoped lang="scss">
.card-eventos {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 335px;
    border: none;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    max-width: 535px;
    min-width: 335px;
    min-height: 400px;
    background-color: white;
    box-shadow: 0 1px 2px -1px rgba(0,0,0,0.3);
    @media (max-width: 676px) {
        max-width: 335px;
    }
}

.image-wrapper {
  display: flex;
  width:100%;
  /*height: 250px;*/
}
/*
@media (max-width: 1904px) {
  .image-wrapper {
    height: 150px;
  }
}
*/

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 8px 16px;
}

.title {
    font-size: 22px;
    text-align: left;
    margin-left: 10px;
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.date {
    font-size: 14px;
    text-align: left;
    color: gray;
    margin-bottom: 10px;
    margin-left: 10px;
}

.button-wrapper {
    display: flex;
}

.button {
    width: 100%;
    background-color: $verde-opus;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
}

.details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.left-side {
    flex: 1;
    text-align: left;
}

.right-side {
    text-align: right;
}

.icon {
    font-size: 24px;
    margin-right: 8px;
}

.place {
  color: black;
  text-align: left;
}

.discount {
    font-size: 18px;
    color: black;
    background-color: gold;
    border-radius: 20px;
    padding: 5px 15px;
}
</style>
