<template>
  <div class="w-100 justify-center bg-black">
    <div class="section">
      <div class="v-col-lg-12 v-col-12 pt-5 bg-black">
        <div class="v-row pa-4">

          <div class="v-col-12 v-col-md-3 pa-4">
            <p class="text-white">Um clube de vantagens exclusivas para você viver o melhor do entretenimento em diferentes regiões do Brasil: <span class="greenText">Rio Grande do Sul, Santa Catarina, Paraná, São Paulo, Pernambuco, Rio Grande do Norte, Ceará.</span></p>
            <div class="d-flex justify-center mt-5">
              <img class="w-50" :src="selo990">
            </div>  
          </div>  

          <div v-if="!xs" class="v-col-12 v-col-md-6">
              <img :src="personagens">
          </div>  

          <div class="v-col-12 v-col-md-3">
            <div class="d-flex justify-center">
              <video :key="video" controls="" :width="xs ? 350 : 250">
                <source :src="video" type="video/mp4">
              </video>
            </div>  
          </div>  

        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { inject } from 'vue';
import personagens from '@/assets/personagens.png';
import selo990 from '@/assets/selo990.png';
//import video from '@/assets/videoHome.mp4';
import { useDisplay } from 'vuetify';

const backendDomain = inject('backendDomain');
const video = backendDomain + '/storage/video/videoHome.mp4'
const { xs } = useDisplay();
</script>
<style scoped>
  .section {
    max-width: 1480px;
    margin: 0 auto;
  }
  p, span {
    font-size:16px;
  }
  img {
    max-width:100%;
    position:relative;
    bottom:0;
  }
  .greenText {
    color: #12af99;
  }
</style>