<template>
    <ul class="nav-links">
        <li class="nav-link">
        </li>
        <li class="nav-link">
            <router-link 
                class="router-links" 
                to="/agenda"
            >
                Agenda
            </router-link>
        </li>
        <li class="nav-link">
            <router-link 
                class="router-links" 
                to="/noticias"
            >
                Notícias
            </router-link>
        </li>
        <li class="nav-link">
            <router-link 
                class="router-links" 
                to="/promocoes"
            >
                Promoções
            </router-link>
        </li>
        <li v-if="!store.customer.isLoggedIn && xs" class="nav-link">
            <router-link 
                class="router-links" 
                to="/facaparte"
            >
                Seja Sócio
            </router-link>
        </li>
        <li v-if="store.customer.isLoggedIn" class="nav-link">
            <router-link 
                class="router-links" 
                to="/perfil"
            >
                Perfil
            </router-link>
        </li>
    </ul>
</template>

<script setup>
import { useStore } from '@/stores';
import { useDisplay } from 'vuetify';
const store = useStore();
const { xs } = useDisplay();
</script>

<style lang="scss">
.nav-links {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    color: white;
    list-style-type: none;

    @media (max-width: 767px) {
        min-height: 148px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-left: 30px;
    }

    .nav-link {
        margin: 0 10px;
    }
    
    .router-links {
        text-decoration: none;
        color: white;
        text-transform: uppercase;
        &:hover {
            opacity: .7;
        }
        @media (max-width: 767px) {
            color: #555;
        }
    }
}
</style>
