<template>

<h1>Perfil</h1>

<div class="w-100" v-if="profile == 'loading'">
  <v-row class="text-center justify-center">
    <v-progress-circular indeterminate color="black" class="align-self-center my-4"></v-progress-circular>
  </v-row>
</div>

<div class="w-100" v-else-if="typeof profile == 'object'">

  <div class="d-flex flex-wrap flex-md-nowrap justify-space-between w-100 gap-10px">

    <v-sheet class="d-flex flex-column flex-wrap align-start pa-3 w-100 rounded-lg">
      <h2 class="mb-4">Olá, <strong>{{ profile.name }}</strong>!</h2>
      <p class="mb-4">Seja bem-vind{{ profile.gender == 'M' ? 'o' : 'a' }} ao Clube Opus.<br />Nesta tela você tem acesso a todas as facilidades do Clube.</p>
      <h4 class="mb-2">Seu status:</h4>
      <div class="d-flex flex-nowrap justify-space-between w-100 gap-10px bg-brown-lighten-5 pa-2 rounded-lg">

        <table class="w-100 text-left">
          <tbody>
            <tr>
              <td class="text-caption text-right py-2 pr-4">Sócio desde:</td>
              <td class="text-caption2 text-left pa-2">{{ profile.subscription_info.starts_at_dmy }}</td>
            </tr>
            <tr>
              <td class="text-caption text-right py-2 pr-4">Validade:</td>
              <td class="text-caption2 text-left pa-2">
                <span class="text-caption2 text-green" v-if="!profile.subscription_info.is_expired">{{ profile.subscription_info.expires_at_dmy }} <v-icon>mdi-check-circle</v-icon></span>
                <span class="text-caption2 text-red" v-else>{{ profile.subscription_info.expires_at_dmy }} <v-icon>mdi-alert</v-icon></span>
              </td>
            </tr>
            <tr>
              <td class="text-caption text-right py-2 pr-4">Situação:</td>
              <td class="text-caption2 text-left pa-2">
                <span class="text-caption2 text-green" v-if="profile.subscription_info.status == 'active'">Ativo <v-icon>mdi-check-circle</v-icon></span>
                <span class="text-caption2" v-else-if="profile.has_pending_cancelation">Cancelamento em análise</span>
                <span class="text-caption2 text-red" v-else>Cancelado <v-icon>mdi-alert</v-icon></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </v-sheet>

    <v-sheet class="d-flex flex-column flex-wrap align-start pa-3 w-100 rounded-lg">
      <h2 class="mb-4">Opções</h2>

      <v-container class="pa-0">

        <v-row align="start" class="text-center">
          <v-col cols="12" lg="4">
            <v-sheet class="btOpcao pa-2 rounded-lg elevation-1" @click="router.push('/agenda')">
              <v-icon>mdi-ticket</v-icon>
              <p class="text-caption text-white mt-1">Agenda de Shows</p>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="4">
            <v-sheet class="btOpcao pa-2 rounded-lg elevation-1" @click="router.push('/promocoes')">
              <v-icon>mdi-gift</v-icon>
              <p class="text-caption text-white mt-1">Promoções</p>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="4" v-if="!profile.subscription_info.is_expired" @click="dialogCartaoCliente = true">
            <v-sheet class="btOpcao pa-2 rounded-lg elevation-1">
              <v-icon>mdi-card-account-details</v-icon>
              <p class="text-caption text-white mt-1">Cartão do Cliente</p>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="4">
            <v-sheet class="btOpcao pa-2 rounded-lg elevation-1" @click="dialogDadosPessoais = true">
              <v-icon>mdi-account</v-icon>
              <p class="text-caption text-white mt-1">Dados pessoais</p>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="4">
            <v-sheet class="btOpcao pa-2 rounded-lg elevation-1" @click="dialogEndereco = true">
              <v-icon>mdi-map-marker</v-icon>
              <p class="text-caption text-white mt-1">Endereço</p>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="4" v-if="profile.subscription_info.status == 'active' && !profile.subscription_info.legacy">
            <v-sheet class="btOpcao pa-2 rounded-lg elevation-1" @click="mostraMetodoPagamento()">
              <v-icon>mdi-credit-card</v-icon>
              <p class="text-caption text-white mt-1">Método de Pagamento</p>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="4" v-if="profile.subscription_info.status == 'active' && !profile.subscription_info.legacy && !profile.subscription_info.free_month">
            <v-sheet class="btOpcao pa-2 rounded-lg elevation-1" @click="dialogCancelamento = true">
              <v-icon>mdi-account-cancel</v-icon>
              <p class="text-caption text-white mt-1">Cancelar Assinatura</p>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="4" v-if="profile.subscription_info.status != 'active' && !profile.has_pending_cancelation">
            <v-sheet class="btOpcao pa-2 rounded-lg elevation-1" @click="reativaAssinatura_opcoes()">
              <v-icon>mdi-account-reactivate</v-icon>
              <p class="text-caption text-white mt-1">Reativar Assinatura</p>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-sheet class="d-flex flex-column flex-wrap align-start pa-3 w-100 rounded-lg">
      <h2 class="mb-4">Mensagens</h2>

      <div class="v-container">

        <div class="v-row text-center justify-center" v-if="atividadeMessage">

          <v-progress-circular indeterminate color="black" class="align-self-center mr-2"></v-progress-circular>

        </div>

        <div class="v-row flex-row-reverse" v-else-if="messages.length > 0">

          <div v-for="(msg, index) of messages" :key="index" class="v-col-lg-12 v-col-12 bg-brown-lighten-5 rounded mb-2  elevation-1">
            <div class="v-row">
              <div class="v-col-2 v-col-lg-1">
                <v-icon :class="msg.type == 'alert'?'text-red':'text-black'">mdi-{{ msg.type }}</v-icon>
              </div>  
              <div class="v-col-10 v-col-lg-8 text-subtitle-2">
                {{ msg.data.title }}
              </div>  
              <div class="d-none d-lg-block v-col-3 text-caption">
                {{ msg.date }}
              </div>  
            </div>
          </div>

        </div>

        <div class="v-row" v-else>

          <div class="v-col-lg-12 v-col-12 text-center my-4">
            <p>Não há novas mensagens</p>
          </div>

        </div>

      </div>
    </v-sheet>

  </div>


</div>

<div v-else class="w-100">
  <div class="flex-row-betweenx">
    <div v-if="tempScreen == 1">
        <p>Ooops... Você precisa entrar para acessar seu perfil.</p>
    </div>
    <div v-else-if="tempScreen == 2">
        <p>Ooops... Houve um erro carregando a página.</p>
    </div>
  </div>
</div>




  <!-- MODAIS -->



  <!-- DADOS PESSOAIS -->

  <v-dialog overflowed 
      v-model="dialogDadosPessoais"
      width="auto"
      max-width="800"
  >
    <v-card>
      <v-card-title>Dados Pessoais</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <CadastroDadosPessoais
          action="update"
          :dados="{
            name: profile.name,
            email: profile.email,
            birth_date: profile.birth_date,
            gender: profile.gender,
            cpf: profile.cpf,
            phone: profile.phone
          }"
          @send-form="atualizaDadosPessoais"
          @close-dialog="dialogDadosPessoais = false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- ENDEREÇO -->

  <v-dialog overflowed 
      v-model="dialogEndereco"
      width="auto"
      max-width="800"
  >
    <v-card>
      <v-card-title>Endereço</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <CadastroEndereco
          action="update"
          :dados="{
            cep: profile.address.cep,
            state: profile.state,
            town: profile.town_id,
            street: profile.address.street,
            number: profile.address.number,
            complement: profile.address.complement,
            district: profile.address.district,
          }"
          @send-form="atualizaEndereco"
          @close-dialog="dialogEndereco = false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- MÉTODO DE PAGAMENTO -->

  <v-dialog overflowed 
    v-model="dialogMetodoPagamento"
    width="auto"
    max-width="800"
  >
    <v-card>
      <v-card-title>Método de Pagamento</v-card-title>
      <v-divider></v-divider>
      <v-card-text>

        <v-sheet v-if="profile.subscription_info.card.has_pending_renew" class="bg-blue-lighten-4 pa-2 rounded-lg w-100 mb-4">
          <v-row>
            <v-col cols="12" class="text-center">
              A atualização do seu cartão será efetivada na próxima fatura.
            </v-col>
          </v-row>
        </v-sheet>

        <v-sheet class="bg-grey-lighten-4 pa-2 rounded-lg w-100">
          <v-row>
            <v-col cols="6" sm="3">
              <p><span class="text-caption text-black">Portador</span><br />{{ profile.subscription_info.card.holder }}</p>
            </v-col>
            <v-col cols="6" sm="3">
              <p><span class="text-caption text-black">Número</span><br />{{ profile.subscription_info.card.number }}</p>
            </v-col>
            <v-col cols="6" sm="3">
              <p><span class="text-caption text-black">Bandeira</span><br />{{ profile.subscription_info.card.brand }}</p>
            </v-col>
            <v-col cols="6" sm="3">
              <p><span class="text-caption text-black">Validade</span><br />{{ profile.subscription_info.card.expires_at }}</p>
            </v-col>
          </v-row>
        </v-sheet>

        <h4 class="mt-4">Definir novo método de pagamento:</h4>

        <CadastroPagamento ref="cadpag1" action="update" @send-form="atualizaMetodoPagamento" @close-dialog="dialogMetodoPagamento = false"/>

        <v-alert v-if="hasCardError" type="warning" text="Houve um erro na validação dos dados do seu cartão. Por favor verifique os dados fornecidos e tente novamente." closable class="my-2 py-2"></v-alert>

      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- CANCELAMENTO DE ASSINATURA -->

  <v-dialog overflowed v-model="dialogCancelamento" width="auto" max-width="800" >

    <v-card>
      <v-card-title>Cancelar assinatura</v-card-title>
      <v-divider></v-divider>
      <v-card-text>

        <div class="mb-5 text-center">

          <v-alert v-if="profile.days_of_membership < 8" density="compact" variant="outlined" type="info" class="myb-5" >
            Você é associado a menos de 7 dias.<br />Caso você não tenha utilizado nenhum dos benefícios do Clube Opus, terá a isenção da multa rescisória de R$ 60,00.<br />Aguarde a análise do cancelamento.
          </v-alert>

          <v-alert v-if="profile.days_of_membership > 7 && profile.months_of_membership < 10" density="compact" variant="outlined" type="warning" class="mb-5" >
            ATENÇÃO: Você é associado a menos de 10 meses.<br />Será cobrada uma multa rescisória de R$ 60,00 (Sessenta reais)
          </v-alert>


          <p class="mb-5">Tem certeza que deseja CANCELAR sua assinatura do Clube Opus?</p>
          
          <v-progress-circular v-if="atividadeCancelamento" indeterminate color="black" class="align-self-center mr-2"></v-progress-circular>

          <div v-else class="d-flex">

            <v-btn
              @click="dialogCancelamento = false"
              class="bg-amber-darken-1 mr-auto"
              rounded
              variant="flat"
            >
              Fechar
            </v-btn>

            <v-btn
              @click="cancelaAssinatura"
              prepend-icon="mdi-account-cancel"
              class="bg-red ml-auto"
              rounded
              variant="flat"
            >
              Cancelar
            </v-btn>

          </div>

        </div>

      </v-card-text>
    </v-card>

  </v-dialog>

  <!-- REATIVAÇÃO DE ASSINATURA -->

  <v-dialog overflowed v-model="dialogReativacao" width="auto" max-width="800" >

    <v-card>
      <v-card-title>Reativar assinatura</v-card-title>
      <v-divider></v-divider>
      <v-card-text>


        <div v-if="telaReativacao == 'opcoes'" class="d-flex flex-column flex-wrap">
          
          <p class="mb-4">Selecione uma opção para reativar a sua assinatura do Clube Opus</p>
          
          <v-progress-circular v-if="opcoesReativacao.length == 0" indeterminate color="black" class="align-self-center mb-4"></v-progress-circular>
          
          <v-select v-else
            v-model="opcaoReativacaoSelecionada"
            label="Opções de pagamento"
            :items="opcoesReativacao"
            item-title="label"
            item-value="value"
            variant="outlined"
            class="w-100 mb-4"
          ></v-select>

          <v-progress-circular v-if="atividadeReativacao" indeterminate color="black" class="align-self-center mr-2"></v-progress-circular>

          <div v-else class="d-flex">

            <v-btn
              @click="dialogReativacao = false"
              class="bg-amber-darken-1 mr-auto"
              rounded
              variant="flat"
            >
              Fechar
            </v-btn>

            <v-btn
              @click="reativaAssinatura_escolhe()"
              class="bg-amber-darken-1 ml-auto"
              rounded
              variant="flat"
            >
              Prosseguir
            </v-btn>

          </div>


        </div>
        
        <div v-else-if="telaReativacao == 'novo-cartao'" class="d-flex flex-column flex-wrap">
          <CadastroPagamento ref="cadpag2" action="update" @send-form="reativaAssinatura_novoCartao" @close-dialog="dialogReativacao = false" />
          <v-alert v-if="hasCardError" type="warning" text="Houve um erro na validação dos dados do seu cartão. Por favor verifique os dados fornecidos e tente novamente." closable class="my-2 py-2"></v-alert>
        </div>
        
        <div v-else-if="telaReativacao == 'processando-novo-cartao'" class="d-flex flex-column flex-wrap text-center">
          <v-progress-circular indeterminate color="black" class="align-self-center mr-2"></v-progress-circular>
          <p>Aguarde, processando seu cartão...</p>
        </div>
        
        <div v-else-if="telaReativacao == 'finalizacao'" class="d-flex flex-column flex-wrap">
          <p class="my-5">Sua solicitação de assinatura do Clube Opus foi processada com sucesso.</p>
          <v-btn
            @click="dialogReativacao = false"
            class="bg-amber-darken-1 align-self-center"
            rounded
            variant="flat"
          >
            CONCLUIR
          </v-btn>
        </div>

      </v-card-text>
    </v-card>

  </v-dialog>


  <!-- CARTÃO DO CLIENTE -->

  <v-dialog
    fullscreen
    :scrim="true"
    transition="dialog-bottom-transition"
    v-model="dialogCartaoCliente"
  >

    <v-card>
      <v-card-text class="pa-0">
        <v-container>
          <v-row class="text-center justify-center">
            <v-col cols="12" lg="4" class="cartao" :style="{ backgroundImage: 'url(' + logoSvg + ')' }">
              <v-row>
                <v-col cols="12">
                  <v-icon @click="dialogCartaoCliente = false" class="float-right">mdi-close</v-icon>
                </v-col>
              </v-row>
              <v-sheet class="w-100 rounded-xl bg-white mx-auto pa-4 info">
                
                <v-sheet class="rounded-xl bg-white mx-auto foto" :style="{ backgroundImage: 'url(' + (profile.profile_pic == false ? avatar : profile.profile_pic) + ')' }" @click.prevent="selecionaFoto">
                  <v-progress-linear v-if="subindoFoto" :model-value="progressoUpload" :height="5"></v-progress-linear>
                </v-sheet>

                <p><span class="clabel">Nome</span><br />{{ profile.name }}</p>
                <p><span class="clabel">E-mail</span><br />{{ profile.email }}</p>
                <p><span class="clabel">cpf</span><br />{{ profile.cpf }}</p>
                <p><span class="clabel">data inicial da assinatura</span><br />{{ profile.subscription_info.starts_at_dmy }}</p>
                <p><span class="clabel">validade da assinatura*</span><br /><span :class="profile.subscription_info.is_expired ? 'text-red':''">{{ profile.subscription_info.expires_at_dmy }}</span></p>

                <div class="aviso-validade">* A VALIDADE É ATUALIZADA MENSALMENTE APÓS O PAGAMENTO DA MENSALIDADE</div>

                <input ref="inputFoto" type="file" style="display:none" name="foto" id="fotoCliente" @change="sobeFoto">

              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>





  </v-dialog>



  <!-- SNACKBAR -->
  <v-snackbar
    v-model="snackbar"
    timeout="3000"
    :color="snackbarType"
  >
    {{ snackbarMsg }}
  </v-snackbar>



</template>

<script setup>
import { computed, inject, onMounted, ref, watch } from 'vue';
import { useStore } from '@/stores';
import CadastroDadosPessoais from './CadastroDadosPessoais.vue';
import CadastroEndereco from './CadastroEndereco.vue';
import CadastroPagamento from './CadastroPagamento.vue';
import { useRouter } from 'vue-router';
import logoSvg from '@/assets/whiteLogo.svg';
import avatar from '@/assets/avatar.png';
const axios = inject('axios');
const backendUrl = inject('backendUrl');
const defaultPaymentGateway = inject('defaultPaymentGateway');
const pagarmePublicKey = inject('pagarmePublicKey');
const store = useStore();
const router = useRouter();
const profile = ref('loading');
const tempScreen = ref(0);

//snackbar
const snackbar = ref(false);
const snackbarMsg = ref('');
const snackbarType = ref('success');
const showSnack = (msg, type) => {
  snackbarMsg.value = msg;
  snackbarType.value = type;
  snackbar.value = true
}

//busca dados do cliente
const buscaPerfil = () => {
  profile.value = 'loading';
  axios.get(
    backendUrl + '/customer/profile'
    ).then((response) => {
      profile.value = response.data;
      store.storeCustomer({
        id: response.data.id,
        name: response.data.name,
        email: response.data.email,
        isLoggedIn: true,
        isExpired: response.data.subscription_info.is_expired,
        expiresAt: response.data.subscription_info.expires_at,
      });
      buscaMensagens();
    }).catch((error) => {
      profile.value = 'error';
      if(error.response.status == '401') {
        tempScreen.value = 1;
      } else {
        tempScreen.value = 2;
      }
      store.logout();
    }
  );
}

//atualização dados pessoais
const dialogDadosPessoais = ref(false);
const atividadeDadosPessoais = ref(false);
const atualizaDadosPessoais = (dados) => {
  atividadeDadosPessoais.value = true;
  axios.post(
    backendUrl + '/customer/update-personal-data',
    dados
    ).then((response) => {
      profile.value = response.data;
      atividadeDadosPessoais.value = false;
      dialogDadosPessoais.value = false;
      showSnack('Dados atualizados com sucesso', 'success');
    }).catch((error) => {
      atividadeDadosPessoais.value = false;
      dialogDadosPessoais.value = false;
      showSnack('Erro salvando dados', 'danger');
    }
  );
};

//atualização endereço
const dialogEndereco = ref(false);
const atividadeEndereco = ref(false);
const atualizaEndereco = (dados) => {
  atividadeEndereco.value = true;
  axios.post(
    backendUrl + '/customer/update-address',
    dados
    ).then((response) => {
      profile.value = response.data;
      atividadeEndereco.value = false;
      dialogEndereco.value = false;
      showSnack('Dados atualizados com sucesso', 'success');
    }).catch((error) => {
      atividadeEndereco.value = false;
      dialogEndereco.value = false;
      showSnack('Erro salvando dados', 'danger');
    }
  );
};

//atualização cartão (cria novo)
const cadpag1 = ref(null);
const dialogMetodoPagamento = ref(false);
const atividadeMetodoPagamento = ref(false);
const mostraMetodoPagamento = () => {
  dialogMetodoPagamento.value = true;
  hasCardError.value = false;
}
const atualizaMetodoPagamento = async (dados) => {
  console.log(dados);
  hasCardError.value = false;
  //cria token do cartão
  const tokenizeData = {
    card: {
      number: dados.cardNumber,
      holder_name: dados.firstName + ' ' + dados.lastName,
      holder_document: profile.value.cpf,
      exp_month: dados.expiresAt.split('/')[0],
      exp_year: dados.expiresAt.split('/')[1],
      cvv: dados.cvv
    },
    type: 'card'
  };
  let tokenizedCard = await geraToken(tokenizeData);
  if(tokenizedCard == false) {
    cadpag1.value.isSending = false;
    hasCardError.value = true;
    return false;
  }
  axios.get(
    backendUrl + `/subscription/update-card/` + tokenizedCard.id,
  ).then((response) => {
    buscaMensagens();
    if(response.data.success) {
      profile.value.subscription_info = response.data
      dialogMetodoPagamento.value = false;
      showSnack('Dados atualizados com sucesso', 'success');
    } else {
      cadpag1.value.isSending = false;
      hasCardError.value = true;
      showSnack('Erro atualizando cartão', 'danger');
    }
  });
};


//busca mensagens
const messages = ref([]);
const noMessages = ref(false);
const atividadeMessage = ref(false);
const buscaMensagens = () => {
  atividadeMessage.value = true;
  axios.get(
    backendUrl + '/customer/messages'
    ).then((response) => {
      atividadeMessage.value = false;
      if(response.data.length > 0) {
        messages.value = response.data;
        noMessages.value = false;
      } else {
        noMessages.value = true;
      }
    }).catch((error) => {
      atividadeMessage.value = false;
      //
    }
  );
};

//cancela assinatura
const dialogCancelamento = ref(false);
const atividadeCancelamento = ref(false);
const cancelaAssinatura = () => {
  atividadeCancelamento.value = true;
  axios.get(
    backendUrl + '/subscription/cancel'
    ).then((response) => {
      buscaPerfil();
      if(response.data.success) {
        atividadeCancelamento.value = false;
        //profile.value.subscription_info = response.data;
        dialogCancelamento.value = false;
        showSnack('Assinatura cancelada', 'success');
      } else {
        showSnack('Erro ao cancelar assinatura', 'danger');
      }
    }).catch((error) => {
      //
    }
  );
};

//loga atividade do cliente
const logEntry = (message, status) => {
  axios.post(
    backendUrl + '/customer/log',
    {message: message, status: status}
  );
}

//cartão do cliente
const dialogCartaoCliente = ref(false);
watch(dialogCartaoCliente, (value) => {
  if(value === true) {
    logEntry('Visualizou cartão', 'info');
  }
})

const foto = ref(null);
//upload foto
const fileName = computed(() => foto.value?.name);
const fileExtension = computed(() => fileName.value?.substr(fileName.value?.lastIndexOf(".") + 1));
const fileMimeType = computed(() => foto.value?.type);
const inputFoto = ref(null);
const selecionaFoto = () => {
    inputFoto.value.click();
};
const subindoFoto = ref(false);
const progressoUpload = ref(0);
const sobeFoto = (event) => {
  foto.value = event.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(foto.value);
  reader.onload = async () => {
    if(!['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension.value)) {
      alert('Tipo de arquivos aceitos: JPG, PNG ou GIF');
      return false;
    }
    const encodedFile = reader.result.split(",")[1];
    const data = {
      file: encodedFile,
      fileName: fileName.value,
      fileExtension: fileExtension.value,
      fileMimeType: fileMimeType.value,
    };
    try {
      subindoFoto.value = true;
      const response = await axios.post(
        backendUrl + '/customer/upload-photo',
        data,
        {
          onUploadProgress: function( progressEvent ) {
            progressoUpload.value = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            console.log(progressoUpload.value);
          }.bind(this)
        }
      );
      subindoFoto.value = false;
      console.log(response.data);
      profile.value.profile_pic = response.data.photo;
    } catch (error) {
      subindoFoto.value = false;
      console.error(error);
    }
  };
}






//CLIENTES INATIVOS:

const dialogReativacao = ref(false);
const opcoesReativacao = ref([]);
const opcaoReativacaoSelecionada = ref(null);
const telaReativacao = ref('opcoes');
const atividadeReativacao = ref(false);
const hasCardError = ref(false);

//reativação: retorna opções de reativação
const reativaAssinatura_opcoes = () => {
  telaReativacao.value = 'opcoes';
  opcoesReativacao.value = [];
  dialogReativacao.value = true;
  axios.get(
    backendUrl + '/subscription/renewal-options'
    ).then((response) => {
      buscaMensagens();
      if(!response.data.options.length) {
        dialogReativacao.value = false;
        return false;
      }
      opcoesReativacao.value = response.data.options;
      opcaoReativacaoSelecionada.value = response.data.options[0].value;
      //se única opção é inserir cartão novo, vamos direto pro form do cartão
      if(response.data.options.length == 1) {
        telaReativacao.value = 'novo-cartao';
      }
    }).catch((error) => {
      //
    }
  );
};

//reativação: executa opção selecionada
const reativaAssinatura_escolhe = () => {
  if(['newCard', 'newSubscription'].includes(opcaoReativacaoSelecionada.value)) {
    //cria novo cartão
    telaReativacao.value = 'novo-cartao';
  } else {
    //utiliza um cartão existente
    atividadeReativacao.value = true;
    axios.get(
      backendUrl + '/subscription/renew-with-existing-card/' + opcaoReativacaoSelecionada.value
      ).then((response) => {
        buscaMensagens();
        atividadeReativacao.value = false;
        if(response.data.success) {
          telaReativacao.value = 'finalizacao';
          profile.value.subscription_info = response.data;
          showSnack('Assinatura reativada com sucesso', 'success');
        } else {
          showSnack('Erro ao reativar assinatura', 'danger');
        }
      }).catch((error) => {
        atividadeReativacao.value = false;
        //
      }
    );
  }
};

//reativação: cadastra novo cartão para reativar assinatura
const cadpag2 = ref(null);
const reativaAssinatura_novoCartao = async (dados) => {
  switch(defaultPaymentGateway) {
    case 'pagarme':
      telaReativacao.value = 'processando-novo-cartao';
      hasCardError.value = false;
      //cria token do cartão
      const tokenizeData = {
        card: {
          number: dados.cardNumber,
          holder_name: dados.firstName + ' ' + dados.lastName,
          holder_document: profile.value.cpf,
          exp_month: dados.expiresAt.split('/')[0],
          exp_year: dados.expiresAt.split('/')[1],
          cvv: dados.cvv
        },
        type: 'card'
      };
      let tokenizedCard = await geraToken(tokenizeData);
      if(tokenizedCard == false) {
        telaReativacao.value = 'novo-cartao';
        cadpag2.value.isSending = false;
        hasCardError.value = true;
        return false;
      }
      /*
      {
        "id": "token_mkNE805uOuyZ9Q75",
        "type": "card",
        "created_at": "2023-09-01T15:19:39Z",
        "expires_at": "2023-09-01T15:20:39Z",
        "card": {
          "first_six_digits": "403203",
          "last_four_digits": "4480",
          "holder_name": "Chris Leyen",
          "holder_document": "57808619000",
          "exp_month": 11,
          "exp_year": 28,
          "brand": "Visa"
        }
      } 
      */
      axios.get(
        backendUrl + `/subscription/renew-with-new-card/` + tokenizedCard.id,
      ).then((response) => {
        buscaMensagens();
        if(response.data.success) {
          telaReativacao.value = 'finalizacao';
          profile.value.subscription_info = response.data
          showSnack('Assinatura reativada com sucesso', 'success');
        } else {
          telaReativacao.value = 'novo-cartao';
          cadpag2.value.isSending = false;
          hasCardError.value = true;
          showSnack('Erro ao reativar assinatura', 'danger');
        }
      });
      break;

    default:
      alert('Erro: gateway de pagamento não disponível');
      break;

  }
};


//gera token do cartão de crédito
const geraToken = async (tokenizeData) => {
  switch(defaultPaymentGateway) {
    case 'pagarme':
      try {
        let response = await axios({
          method: 'post',
          url: 'https://api.pagar.me/core/v5/tokens?appId=' + pagarmePublicKey,
          withCredentials: false,
          withXSRFToken: false,
          data: tokenizeData,
        })
        return response.data;
      } catch (error) {
        return false;
      }
      break;

    default:
      alert('Erro: gateway de pagamento não disponível');
      break;
  }
}



onMounted(() => {

  buscaPerfil();

  /*
  messages.value = JSON.parse(`[
    {
        "id": "9a4829b7-9708-46fa-a654-6d92ebf55f77",
        "customer_id": 35,
        "type": "alert",
        "data": {
            "title": "teste",
            "text": "abc"
        },
        "read_at": null,
        "created_at": "2023-10-03T15:34:42.000000Z",
        "updated_at": "2023-10-03T15:34:42.000000Z",
        "date": "03/10/2023"
    },
    {
        "id": "9a4872cb-101b-414e-b80e-6af468303f9f",
        "customer_id": 35,
        "type": "chat",
        "data": {
            "title": "Bem vindo ao Clube Opus",
            "text": ""
        },
        "read_at": null,
        "created_at": "2023-10-03T18:59:02.000000Z",
        "updated_at": "2023-10-03T18:59:02.000000Z",
        "date": "03/10/2023"
    }
  ]`);


  
  profile.value = JSON.parse(`{
      "id": 1,
      "name": "Adriane Albuquerque de Oliveira",
      "cpf": "01145510060",
      "birth_date": "04\/12\/1989",
      "email": "adriane@hte.com.br",
      "email_verified_at": null,
      "phone": "51981837339",
      "state": "RS",
      "town_id": 4314902,
      "address": {
          "cep": "91510000",
          "street": "Rua Coronel Jos\u00e9 Rodrigues Sobral",
          "number": "471",
          "complement": "21",
          "district": "Partenon"
      },
      "gender": "F",
      "two_factor_confirmed_at": null,
      "uuid": "60a20f75-cf30-43b7-a4b4-cea164e16cff",
      "active": 1,
      "created_at": "2017-05-01T11:43:33.000000Z",
      "updated_at": "2023-10-30T21:50:19.000000Z",
      "deleted_at": null,
      "town_name": "Porto Alegre",
      "num_checks": 0,
      "num_messages": 0,
      "days_of_membership": 3,
      "months_of_membership": 0,
      "subscription_info": {
          "success": true,
          "id": 1,
          "gateway_id": 1,
          "gateway": "iugu",
          "plan": "IUGU - Assinatura Clube Opus",
          "price_cents": 990,
          "price_brl": "R$ 9,90",
          "starts_at": "2023-10-30",
          "starts_at_dmy": "30\/10\/2023",
          "expires_at": "2031-09-29",
          "expires_at_dmy": "29\/09\/2031",
          "is_expired": false,
          "current_cycle": {
              "starts_at": "2023-10-30",
              "starts_at_dmy": "30\/10\/2023",
              "status": "paid"
          },
          "status": "active",
          "card": {
              "type": "---",
              "brand": "---",
              "holder": "---",
              "number": "---",
              "expires_at": "--\/--",
              "has_pending_renew": 0
          },
          "legacy": true
      },
      "has_pending_cancelation": false,
      "profile_pic": false
  }`);

  */
  
  
});
</script>

<style lang="scss" scoped>
.text-caption {
  line-height: 1rem;
}
.btOpcao {
  background-color:#03af9a;
  height: 75px;
  cursor: pointer;
}
.mensagem {
  background: linear-gradient(0deg, rgba(1,143,126,1) 0%, rgba(17,177,157,1) 73%, rgba(3,175,154,1) 92%);
  color: white;
  height: 80px;
  cursor: pointer;
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.44);
}
.label {
  color: gray;
  font-size: 12px;
}
.cartao {
  background-color: #00d6ce;
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: 50% 0;
  height: 100vh;
  @media (max-width: 676px) {
    background-size: 60%;
  }
  .info {
    margin-top:120px;
    @media (max-width: 676px) {
      margin-top:80px;
    }
  }
  .foto {
    background-size: cover;
    width: 50%;
    border: 3px solid #00a7c1;
    aspect-ratio: 1/1;
    padding: 10px;
  }
  p, span {
    text-transform: uppercase;
    margin-top: 20px;
    width: 100%;
    text-align: left;
    line-height:18px;
    font-size: 14px;
  }
  .clabel {
    color: #58b9d1;
  }
  .aviso-validade {
    background-color: #00c0bf;
    color: white;
    width:100%;
    margin-top: 30px;
    padding: 4px 0;
    font-size: 8px;
    text-align: center;
  }
}
</style>
