<template lang="html">
  <div class="cadastro">

    <h3 class="mb-5">Parabéns!</h3>
    <p>Você concluiu seu cadastro com sucesso!</p>
    <p>A partir da aprovação do pagamento, você já pode utilizar todos os benefícios disponíveis no Clube Opus.</p>
    <p>Confira seu email.</p><br />
    <p>Equipe Opus</p>

  </div>
</template>

<style lang="scss" scoped>
.cadastro {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  min-height:400px;
  .flex-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0 26px;
    > * {
        min-width: 300px;
        max-width: 608px;
    }
  }
  h2 {
    margin-bottom:20px
  }
  .botao {
    align-self: flex-end;
  }
}
</style>
