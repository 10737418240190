<template>

    <h1>Agenda</h1>

    <v-container class="pa-0 mx-0">
      <v-row class="mb-4">
        <v-col cols="12" lg="3">
          <v-text-field
            v-model="searchForm.title"
            id="titulo"
            label="Nome do Evento" 
            variant="outlined"
            bg-color="white"
            hide-details
            clearable
            clear-icon="mdi-close"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="3">
          <v-text-field 
            v-model="searchForm.town"
            id="cidade"
            label="Cidade" 
            variant="outlined"
            bg-color="white"
            hide-details
            clearable
            clear-icon="mdi-close"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="2">
          <v-text-field 
            v-model="searchForm.date"
            id="data"
            label="Data mm/aaaa" 
            variant="outlined"
            bg-color="white"
            color="#03af9a"
            hide-details
            v-maska:[dateMask]
            clearable
            clear-icon="mdi-close"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="3">
          <v-select
            v-model="searchForm.order"
            :items="[{value:'date', title:'Data'}, {value:'discount_desc', title:'Maior desconto'}, {value:'discount', title:'Menor desconto'}]"
            id="order"
            label="Ordenar por..." 
            variant="outlined"
            bg-color="white"
            color="#03af9a"
            hide-details
          >
          </v-select>
        </v-col>
        <v-col align-self="center" cols="12" lg="1">
          <v-btn
            class="bg-amber-darken-1 w-100"
            rounded
            variant="flat"
            @click="fetchEvents"
          >
            BUSCAR
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="pa-0 mx-0">

      <v-row v-if="loadingEvents" class="text-center justify-center">
        <v-progress-circular indeterminate color="black" class="align-self-center my-4"></v-progress-circular>
      </v-row>

      <v-row v-else-if="eventos.length > 0" class="text-center">
        <CardEvento
          v-for="(evento, index) of eventos" :key="index"
          :id="evento.id"
          :image="evento.image2"
          :title="evento.title"
          :date="evento.full_dates"
          :place="evento.place_name"
          :discount="evento.discount"
          :url="evento.url"    
        />
      </v-row>

      <v-row v-else class="justify-center">
        <p class="text-center my-4">Não há eventos cadastrados</p>
      </v-row>


    </v-container>

</template>

<script setup>
  import { inject, onMounted, ref, watch } from 'vue';
  import CardEvento from '@/components/CardEvento.vue'
  import Button from '@/components/Button.vue'
  import throttle from "lodash/throttle";
  import { vMaska } from "maska";
  import { useDisplay } from 'vuetify';

  const axios = inject('axios');
  const backendUrl = inject('backendUrl');
  const csrfUrl = inject('csrfUrl');
  const eventos = ref([]);
  const dateMask = { mask: '##/####' };
  const xs = useDisplay();
  const loadingEvents = ref(true);

  //filtros
  let searchForm = ref({
    title: '',
    town: '',
    date: '',
    order: 'date',
  });
  /*
  //busca ao digitar
  watch(
    searchForm,
  throttle(() => {
    fetchEvents();
  }, 600), {
    deep: true
  });
  */

  //busca eventos
  const fetchEvents = async () => {
    loadingEvents.value = true;
    await axios.get(csrfUrl); 
    axios.post(
      backendUrl + '/events',
      {filters: searchForm.value},
    ).then((response) => {
      loadingEvents.value = false;
      eventos.value = response.data;
    });
  }

  onMounted(() => {
    fetchEvents();
    window.scrollTo(0,0);
  })
</script>
