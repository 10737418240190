<template>
    <button  
        :class="{ 'button-icon': type === 'icon', 'button-text': type !== 'icon' }" 
        @click="handleClick"
    >
        <v-icon
            v-if="type == 'icon'" 
            class="icon"
        >
            {{icon}}
        </v-icon>
        <span v-else>{{ buttonText }}</span>
    </button>
</template>

<script setup>
import { useRouter } from 'vue-router';

const props = defineProps({
    type: {
        type: String,
        default: 'text',
        validator: (value) => ['text', 'icon'].includes(value),
    },
    buttonText: {
        type: String,
        default: 'Button',
    },
    to: {
        type: [String, Object],
        default: null,
    },
    href: {
        type: String,
        default: null,
    },
    icon: {
        type: String,
        default: null,
    },
});

const router = useRouter();

const handleClick = () => {
    if (props.to) {
        router.push(props.to);
    } else if (props.href) {
        window.location.href = props.href;
    }
};

</script>

<style scoped lang="scss">
.button-text {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    background-color: #ffc107;
    border: none;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    color: black;
    text-transform: uppercase;
    transition: all 0.3s ease;
    &:hover {
        background-color: #3f9fff;
        color: white;
    }
}
.button-icon {
    // padding: 8px;
    background-color: transparent !important;
    color: white !important;
    border: none;
}


</style>
