<template>
  <v-dialog overflowed width="auto" max-width="100%"  >

      <v-card>
      <v-card-title>Regulamento e Termos de Uso Clube Opus</v-card-title>
      <v-divider></v-divider>
      <v-card-text>

          <div class="regulamento mb-5" v-html="rules">
          </div>

          <v-divider></v-divider>

          <div class="text-center justify-center">

            <v-btn
            @click="closeDialog"
            class="bg-amber-darken-1 mr-auto my-5"
            rounded
            variant="flat"
            >
            Fechar
            </v-btn>

        </div>


      </v-card-text>
      </v-card>

  </v-dialog>
</template>

<script setup>
import { inject, onMounted, ref } from 'vue';

const axios = inject('axios');
const backendUrl = inject('backendUrl');
const rules = ref('');

//fecha dialog
const emit = defineEmits(["close-dialog"]);
const closeDialog = () => {
  emit("close-dialog");
}

onMounted(() => {
  axios.get(
    backendUrl + '/get-text/1' //busca texto dos termos de uso (id = 1)
  ).then((response) => {
    rules.value = response.data.content.text;
  });
})

</script>

<style lang="scss">
.regulamento {
  ul {
    padding-left:40px;
    color:#777777;
  }
}
</style>