<template>

    <h1>Promoções</h1>

    <v-container class="pa-0 mx-0">
      <v-row class="mb-4">
        <v-col cols="12" lg="6">
          <v-text-field
            v-model="searchForm.term"
            id="termo"
            label="Termo de busca" 
            variant="outlined"
            bg-color="white"
            hide-details
            clearable
            clear-icon="mdi-close"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="5">
          <v-text-field 
            v-model="searchForm.date"
            id="data"
            label="Data mm/aaaa" 
            variant="outlined"
            bg-color="white"
            color="#03af9a"
            hide-details
            v-maska:[dateMask]
            clearable
            clear-icon="mdi-close"
          >
          </v-text-field>
        </v-col>
        <v-col align-self="center" cols="12" lg="1">
          <v-btn
            class="bg-amber-darken-1 w-100"
            rounded
            variant="flat"
            @click="fetchPromotions"
          >
            BUSCAR
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="pa-0 mx-0">

      <v-row v-if="loadingPromotions" class="text-center justify-center">
        <v-progress-circular indeterminate color="black" class="align-self-center my-4"></v-progress-circular>
      </v-row>

      <v-row v-else-if="promocoes.length > 0">
        <CardPromocao v-for="(promocao, index) of promocoes" :key="index" :promocao="promocao"/>
      </v-row>

      <v-row v-else class="justify-center">
        <p class="text-center my-4">Não há promoções em andamento</p>
      </v-row>

    </v-container>

</template>

<script setup>
    import { inject, onMounted, ref } from 'vue';
    import { useDisplay } from 'vuetify';
    import { useStore } from '@/stores';
    import CardPromocao from './CardPromocao.vue';
    import { vMaska } from "maska";

    const axios = inject('axios');
    const backendUrl = inject('backendUrl');
    const csrfUrl = inject('csrfUrl');
    const promocoes = ref([]);
    const { xs } = useDisplay();
    const store = useStore();
    const loadingPromotions = ref(true);
    const dateMask = { mask: '##/####' };

    //filtros
    let searchForm = ref({
      term: '',
      date: '',
    });

    //busca promoções
    const fetchPromotions = async () => {
      loadingPromotions.value = true;
      await axios.get(csrfUrl); 
      axios.post(
        backendUrl + '/promotions',
        {filters: searchForm.value},
      ).then((response) => {
        loadingPromotions.value = false;
        promocoes.value = response.data;
      });
    }

    onMounted(() => {
      fetchPromotions();
    })

    /*
    onMounted(() => {
      loadingPromotions.value = true;
      axios.get(backendUrl + '/promotions').then((response) => {
        loadingPromotions.value = false;
        promocoes.value = response.data;
      });
    })
    */
</script>

