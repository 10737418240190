<template>

    <h1>Seja Sócio</h1>
    <h2>Por apenas R$ 9,90 / mensais*</h2>
    <p>Com o Clube Opus você adquire benefícios para os shows realizados pela Opus Entretenimento.</p>
    <p>É muito fácil fazer parte. Cadastre-se aqui e aproveite as exclusividades para sócios. <a class="text-black" style="cursor:pointer" @click="rulesDialog = true">Confira o regulamento completo</a>.</p>

    <!-- titulos das tabs -->
    <v-tabs
      v-model="currentTab"
      bg-color="#03af9a"
      class="rounded-t-lg mt-4 px-1"
      stacked
    >

      <v-tab
        value="tabDados"
        :disabled="currentTab == 'tabDados'?false:true"
      >
        <v-icon>mdi-account</v-icon>
        <span v-if="!xs">Dados</span>
      </v-tab>

      <v-tab
        value="tabEndereco"
        :disabled="currentTab == 'tabEndereco'?false:true"
      >
        <v-icon>mdi-map-marker</v-icon>
        <span v-if="!xs">Endereço</span>
      </v-tab>

      <v-tab
        value="tabCupom"
        v-if="showCouponTab == true"
        :disabled="currentTab == 'tabCupom'?false:true"
      >
        <v-icon>mdi-ticket-percent</v-icon>
        <span v-if="!xs">Cupom</span>
      </v-tab>

      <v-tab
        value="tabPagamento"
        :disabled="currentTab == 'tabPagamento'?false:true"
      >
        <v-icon>mdi-credit-card</v-icon>
        <span v-if="!xs">Pagamento</span>
      </v-tab>

      <v-tab
        value="tabConclusao"
        :disabled="currentTab == 'tabConclusao'?false:true"
      >
        <v-icon>mdi-check-decagram</v-icon>
        <span v-if="!xs">Conclusão</span>
      </v-tab>

    </v-tabs>

    <!-- páginas das tabs -->
    <v-window v-model="currentTab" style="width: 100%;" class="cadastro-bg">
    
      <!-- página Dados Pessoais -->
      <v-window-item value="tabDados">
        <h2>1. Dados Pessoais</h2>
        <CadastroDadosPessoais action="create" @send-form="processaDadosPessoais" />
      </v-window-item>

      <!-- página Endereço -->
      <v-window-item value="tabEndereco">
        <h2>2. Endereço</h2>
        <CadastroEndereco action="create" @send-form="processaEndereco" />
      </v-window-item>

      <!-- página Cupom -->
      <v-window-item value="tabCupom" v-if="showCouponTab == true">
        <h2>3. Cupom</h2>
        <CadastroCupom @send-form="processaCupom" />
      </v-window-item>

      <!-- página Forma de Pagamento -->
      <v-window-item value="tabPagamento">
          <h2>{{ showCouponTab ? '4' : '3'}}. Pagamento</h2>
          <CadastroPagamento ref="cadpag" action="create" @send-form="processaPagamento" />
      </v-window-item>

      <!-- página Conclusão -->
      <v-window-item value="tabConclusao">
        <h2>{{ showCouponTab ? '5' : '4'}}. Conclusão</h2>
        <CadastroConclusao />
      </v-window-item>

      <v-alert v-if="hasCardError" type="warning" text="Houve um erro na validação dos dados do seu cartão. Por favor verifique os dados fornecidos e tente novamente." closable class="my-2 py-2"></v-alert>

    </v-window>

    <Rules v-model="rulesDialog" @close-dialog="rulesDialog = false" />

</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue';
import CadastroDadosPessoais from '@/components/CadastroDadosPessoais.vue';
import CadastroEndereco from '@/components/CadastroEndereco.vue';
import CadastroPagamento from '@/components/CadastroPagamento.vue';
import CadastroCupom from '@/components/CadastroCupom.vue';
import CadastroConclusao from '@/components/CadastroConclusao.vue';
import Rules from '@/components/Rules.vue';
import { useDisplay } from 'vuetify';

const defaultPaymentGateway = inject('defaultPaymentGateway');
const pagarmePublicKey = inject('pagarmePublicKey');
const axios = inject('axios');
const backendUrl = inject('backendUrl');
const csrfUrl = inject('csrfUrl');
const { xs } = useDisplay();
const currentTab = ref('tabDados');
const hasCardError = ref(false);
const showCouponTab = ref(false);
const cadastro = ref({
  name: null,
  email: null,
  birth_date: null,
  gender: null,
  cpf: null,
  phone: null,
  password: null,
  address: {
    cep:null,
    state:null,
    town:null,
    street:null,
    number:null,
    complement:null,
    district:null,
  },
  card: null,
  coupon: false,
});
const cadpag = ref(null);

const rulesDialog = ref(false);

const processaDadosPessoais = (dados) => {
  cadastro.value.name = dados.name;
  cadastro.value.email = dados.email;
  cadastro.value.birth_date = dados.birth_date;
  cadastro.value.gender = dados.gender;
  cadastro.value.cpf = dados.cpf;
  cadastro.value.phone = dados.phone;
  cadastro.value.password = dados.password;
  //avança para aba de endereço
  currentTab.value = 'tabEndereco';
};

const processaEndereco = (dados) => {
  cadastro.value.address = dados;
  //avança para aba de cupom ou pagamento
  if(showCouponTab.value == true) {
    currentTab.value = 'tabCupom';
  } else {
    currentTab.value = 'tabPagamento';
  }
};

const processaCupom = (dados) => {
  cadastro.value.coupon = dados.coupon;
  console.log(cadastro.value);
  //avança para aba de pagamento
  currentTab.value = 'tabPagamento';
};

const processaPagamento = async (dados) => {

  await axios.get(csrfUrl);
  switch(defaultPaymentGateway) {

    case 'pagarme':
      //cria token do cartão
      let holderName = dados.firstName.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + ' ' + dados.lastName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      const tokenizeData = {
        card: {
          number: dados.cardNumber,
          holder_name: holderName,
          holder_document: cadastro.value.cpf,
          exp_month: dados.expiresAt.split('/')[0],
          exp_year: dados.expiresAt.split('/')[1],
          cvv: dados.cvv
        },
        type: 'card'
      };
      //envia dados para pagarme para criação do token do cartão
      axios({
        method: 'post',
        url: 'https://api.pagar.me/core/v5/tokens?appId=' + pagarmePublicKey,
        withCredentials: false,
        withXSRFToken: false,
        data: tokenizeData,
      }).then(function (response) {
        //verifica se houve erro
        if(response.data.id == undefined) {
          cadpag.value.isSending = false;
          hasCardError.value = true;
          return false;
        }
        //gerou token ok
        cadastro.value.card = response.data;
        console.log(cadastro.value);
        //enviamos dados para o back para finalizar o cadastro
        axios.post(
          backendUrl + '/subscribe',
          cadastro.value,
        ).then((response) => {
          if(response.data.success) {
            console.log(response.data);
            //avança para a aba de conclusão
            currentTab.value = 'tabConclusao';
          } else {
            cadpag.value.isSending = false;
            hasCardError.value = true;
          }
        });
      }).catch(function (error) {
        //erro gerando token
        console.log(error);
        cadpag.value.isSending = false;
        hasCardError.value = true;
        cadastro.value.card = error;
      });
      break;

    default:
      alert('Erro: gateway de pagamento não disponível');
      break;

  }

};

//verifica se existem cupons disponíveis
const verificaExistenciaCupom = async () => {
  let response = await axios.get(backendUrl + '/check-coupon-availability');
  return response.data.coupons;
}

onMounted( async () => {
  showCouponTab.value = await verificaExistenciaCupom();
})


</script>

<style scoped lang="scss">
.cadastro-bg {
    background-color: white;
    padding:20px;
}
/* evita que abas fiquem mais largas que a tela no mobile */
.v-tab {
  min-width: auto;
}
.v-tab.v-tab {
  min-width: auto;
}
</style>