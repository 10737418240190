<template>
<div class="hero">
    <div class="hero-background" :style="{ backgroundImage: `url(${xs?currentSlide.image2:currentSlide.image1})` }">
        <div class="hero-gradient"></div>
    </div>
    <div v-if="currentSlide.text1 != undefined" class="title-wrapper">
        <h1 v-if="currentSlide.text1 != undefined" class="hero-title">{{ currentSlide.text1 }}</h1>
        <h2 v-if="currentSlide.text2 != undefined" class="hero-subtitle">{{ currentSlide.text2 }}</h2>
    </div>
    <div class="slider-dots">
        <span v-if="slides > 2" v-for="(slide, index) in slides" :key="index" :class="{ active: index === currentSlideIndex }" @click="goToSlide(index)"></span>
    </div>
</div>
</template>

<script setup>
import {
    inject,
    computed,
    onMounted,
    onUnmounted,
    ref
} from 'vue';
import {
    useDisplay
} from 'vuetify';

const axios = inject('axios');
const backendUrl = inject('backendUrl');
const slides = ref([{
    text1: '',
    text2: '',
    link: '',
    image1: '',
    image2: ''
}]);
const currentSlideIndex = ref(0);
const currentSlide = computed(() => slides.value[currentSlideIndex.value]);

let timer = null;
const {
    xs
} = useDisplay();

const goToSlide = (index) => {
    currentSlideIndex.value = index;
};

const nextSlide = () => {
    currentSlideIndex.value = (currentSlideIndex.value + 1) % slides.value.length;
};

const startSlider = () => {
    timer = setInterval(nextSlide, 8000);
};

const stopSlider = () => {
    clearInterval(timer);
};

onMounted(() => {
    axios.get(backendUrl + '/banners').then((response) => {
        slides.value = response.data;
        startSlider();
    });
})

onUnmounted(() => {
    stopSlider();
});
</script>



<style scoped lang="scss">
.hero {
    position: relative;
    width: 100%;
    height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    color: white;

    @media (max-width: 600px) {
        height: 310px;
    }
}

.hero-background {
    // z-index: -10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 100%;
    background-color: black;
    // background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.hero-gradient {
    @media(min-width: 1920px) {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        min-height: 100%;
        background-image: linear-gradient(to right, rgba(0,0,0,1) 5%,rgba(0,0,0,0) 30%,rgba(0,0,0,0) 70%,rgba(0,0,0,1) 95%);
    }
}

.title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2% 2% 2% 10%;
    background-color: rgba(0, 0, 0, 0.73);
    z-index: 1;

    @media (max-width: 600px) {
        padding: 5% 5% 10%;
    }
}

.hero-title {
    font-size: 3.5vw;
    font-weight: bold;
    line-height: 3vw;
    margin-bottom: 15px;
    z-index: 2;

    @media (max-width: 600px) {
        font-size: 26px;
    }
}

.hero-subtitle {
    font-size: 2.5vw;
    line-height: 3vw;
    z-index: 1;
    @media (max-width: 600px) {
        font-size: 16px;
    }
}

.slider-dots {
    display: flex;
    justify-content: center;
    align-self: flex-end;
    position: absolute;
    bottom: 10px;
    left: 48%;
    z-index: 10;

    @media (max-width: 600px) {
        left: 43%;
    }

    span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: grey;
        margin: 0 5px;
        cursor: pointer;

        &.active {
            background-color: white;
        }
    }
}
</style>
