<template>
    <nav class="navbar" :class="{ 'scrolled': isScrolled }">
        <v-icon
            id="hamburger-icon"
            :class="{ 'show': showMobileMenu }"
            v-if="xs"
            @click="toggleMobileMenu"
        >
            {{ showMobileMenu ? 'mdi-close' : 'mdi-menu' }}
        </v-icon>

        <img :src="logoSvg" alt="Clube Opus" @click="router.push('/')"/>

        <div class="wrapper">
            <div
                class="menu-mobile"
                :class="{ 'show': showMobileMenu }"
                @click="toggleMobileMenu"
            >
                <Navlinks />
            </div>
            <div class="buttons">
                <Button
                    v-if="!xs && !store.customer.isLoggedIn"
                    buttonText="seja sócio"
                    @click="router.push('/facaparte')"
                />
                <Button
                    id="login-btn"
                    :buttonText="store.customer.isLoggedIn ? 'sair' : 'Login'"
                    :class="{ 'show': showMobileMenu }"
                    :type="xs ? 'icon' : 'text'"
                    :icon="store.customer.isLoggedIn ? 'mdi-exit-run' : 'mdi-account'"
                    @click="store.customer.isLoggedIn ? doLogout() : showLoginForm = true"
                />
            </div>
        </div>
    </nav>
    <v-dialog
        v-model="showLoginForm"
        width="auto"
        max-width="600" 
    >   
        <LoginForm @close-login-dialog="closeLoginDialog"/>
    </v-dialog>
</template>

<script setup>
import { inject, onBeforeUnmount, onMounted, ref } from 'vue';
import { useDisplay } from 'vuetify';
import { useStore } from '@/stores';
import { useRouter } from 'vue-router';
import logoSvg from '@/assets/whiteLogo.svg';
import Navlinks from './Navlinks.vue';
import Button from './Button.vue';
import LoginForm from './LoginForm.vue';

const router = useRouter();
const store = useStore();
const backendUrl = inject('backendUrl');
const axios = inject('axios');
const isScrolled = ref(false);
const showMobileMenu = ref(false);
const showLoginForm = ref(false);
const { xs } = useDisplay();


const handleScroll = () => {
    isScrolled.value = window.scrollY > 0;
};

const toggleMobileMenu = () => {
    showMobileMenu.value = !showMobileMenu.value;
};

const closeLoginDialog = () => {
    showLoginForm.value = false;
};

const doLogout = () => {
    console.log('logout');
    axios.delete(
        backendUrl + '/customer-logout'
    ).then((response) => {
        store.logout();
        router.push('/');
    });
}

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
    if(window.location.hash == '#login') showLoginForm.value = true;
});

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
});

</script>

<style lang="scss">
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8%;
    background-color: transparent;
    color: white;
    transition: background-color 0.3s ease;
    z-index: 11;
    &.scrolled {
        background-color: $verde-opus;
    }
    @media (max-width: 767px) {
        padding: 0 20px;
    }
    img {
        height: 90px;
        cursor: pointer;
        // magin negativa para ajustar paddin do logo.
        margin-left: -22px;
    }
}
#hamburger-icon {
    z-index: 10;
    color: white;
    transition: right 0.9s ease-in;
    &.show {
        position: fixed !important;
        right: 30px !important;
    }
}
.menu-mobile {
    background-color: transparent;
    @media (max-width: 767px) {
        padding-top: 70px;
        background-color: white;
        width: 80%;
        height: 100%;
        position: fixed;
        top: 0;
        left: -80%;
        transition: left 0.3s ease;
    }
    &.show {
        z-index: 100;
        left: 0;
    }
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 14px;
    gap: 14px;
}
#login-btn {
    transition: left 0.3s ease;
    @media (max-width: 767px) {
        &.show {
            position: fixed;
            left: 30px;
        }
    }
}
</style>
