import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import axios from "axios";
import { createGtm } from '@gtm-support/vue-gtm';
import './registerServiceWorker'


loadFonts();

const app = createApp(App);

let backendDomain = null;
let pagarmePublicKey = null;
console.log(window.location.hostname);
switch(window.location.hostname) {

  case 'clubeopus.com':
  case 'www.clubeopus.com':
    backendDomain = 'https://admin.clubeopus.com';
    pagarmePublicKey = 'pk_jQ1mrezILFoyqEL7';
    break;

  case 'clube.opusentretenimento.com':
    backendDomain = 'https://clubeadmin.opusentretenimento.com';
    pagarmePublicKey = 'pk_test_R8rdbGWieT6bWk2n';
    break;

  case 'localhost':
  case '192.168.1.70':
    backendDomain = 'http://localhost:8000';
    pagarmePublicKey = 'pk_test_R8rdbGWieT6bWk2n';
    break;

  default:
    backendDomain = 'https://admin.clubeopus.com';
    pagarmePublicKey = 'pk_jQ1mrezILFoyqEL7';
    break;

}

app.provide('backendDomain', backendDomain)
const backendUrl = backendDomain + '/api';
app.provide('backendUrl', backendUrl)
const csrfUrl = backendDomain + '/sanctum/csrf-cookie';
app.provide('csrfUrl', csrfUrl)

//gateway de pagamento
const defaultPaymentGateway = 'pagarme';
app.provide('defaultPaymentGateway', defaultPaymentGateway)
app.provide('pagarmePublicKey', pagarmePublicKey)

//axios
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
app.provide('axios', axios);

//verifica autenticação
const authCheck = async () => {
  axios.get(
    backendUrl + '/customer/auth-check'
    ).then((response) => {
      return {success:true, status:response.status};
    }).catch((error) => {
      return {success:false, status:error.response.status};
    });
};
app.provide('authCheck', authCheck);

app.use(router)
  .use(vuetify)
  .use(createPinia())
  .use(
    createGtm({
      id: "GTM-NWBNCFX",
      vueRouter: router
    })
  )
  .mount('#app')
