<template lang="html">
  <div class="cadastro">

    <div class="flex-row">
      <p>Você possui um cupom promocional?</p>
    </div>

    <div class="flex-row">
      <v-text-field
      label="Código do Cupom"
      variant="outlined"
      v-model="cupomDigitado"
      class="mt-5"
      >
        <template v-slot:append>
          <v-btn
            class="bg-amber-darken-1 w-100"
            rounded
            variant="flat"
            @click="validaCupom"
          >
            VALIDAR
          </v-btn>
        </template>
      </v-text-field>
    </div>



    <div class="flex-row">

      <p :class="corValidacaoCupom">{{ msgValidacaoCupom }}</p>

    </div>

    <div class="d-flex">

      <v-btn
        @click="sendForm"
        :disabled="isSending"
        class="bg-amber-darken-1 ml-auto"
        rounded
        variant="flat"
      >
        <v-progress-circular v-if="isSending" indeterminate color="black" class="align-self-center mr-2"></v-progress-circular>
        Próximo
      </v-btn>

    </div>

  </div>
</template>

<script setup>
import { inject, ref } from 'vue';

const axios = inject('axios');
const backendUrl = inject('backendUrl');
const emit = defineEmits(["send-form"]);
const isSending = ref(false);
const cupomDigitado = ref('');
const cupomId = ref(0);
const msgValidacaoCupom = ref('');
const corValidacaoCupom = ref('text-green');

//valida cupom digitado
const validaCupom = async () => {
  msgValidacaoCupom.value = '';
  let response = await axios.get(backendUrl + '/check-typed-coupon/' + cupomDigitado.value);
  console.log(response.data);
  if(response.data.id === false) {
    msgValidacaoCupom.value = 'Não foi possível validar o código do cupom.';
    corValidacaoCupom.value = 'text-red';
  } else {
    msgValidacaoCupom.value = 'Código validado com sucesso!';
    corValidacaoCupom.value = 'text-green';
  }
  cupomId.value = response.data.id;
  return cupomId.value;
}

//retorna os dados do form para o parent
const sendForm = async () => {
  isSending.value = true;
  let avisaCupomErrado = false;
  if(cupomDigitado.value != '') {
    let checkCupom = await validaCupom();
    if(checkCupom === false) {
      avisaCupomErrado = true;      
    }
  }
  if(avisaCupomErrado && !confirm('O cupom digitado é inválido.\nDeseja prosseguir assim mesmo?')) {
    isSending.value = false;
    return false;
  }
  emit(
    "send-form",
    {
      coupon: cupomId.value
    }
  );
};


</script>

<style lang="scss" scoped>
.cadastro {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  .flex-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0 26px;
    > * {
        min-width: 300px;
        max-width: 608px;
    }
  }
  h2 {
    margin-bottom:20px
  }
  .botao {
    align-self: flex-end;
  }
}
</style>
