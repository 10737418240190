import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/views/Layout.vue'
import HomeView from '@/views/HomeView.vue'
import AgendaView from '@/views/AgendaView.vue'
import PerfilView from '@/views/PerfilView.vue'
import RedefinirSenhaView from '@/views/RedefinirSenhaView.vue'
import CadastroView from '@/views/CadastroView.vue'
import NoticiasView from '@/views/NoticiasView.vue'
import NoticiaView from '@/views/NoticiaView.vue'
import PromocoesView from '@/views/PromocoesView.vue'

const routes = [
    {
        path: '/',
        component: Layout,
        meta: {
            title: 'Home',
        },
        children: [
            {
                path: '',
                component: HomeView,
            },
            {
                path:':modal',
                component: HomeView,
            }
        ],
    },
    {
        path: '/agenda',
        component: Layout,
        meta: {
          title: 'Agenda',
        },
        children: [
            {
                path: '',
                component: AgendaView,
            },
        ],
    },
    {
        path: '/perfil',
        component: Layout,
        meta: {
          title: 'Perfil',
        },
        children: [
            {
                path: '',
                component: PerfilView,
            },
        ],
    },
    {
        path: '/redefinir-senha/:token',
        component: Layout,
        meta: {
          title: 'Redefinir senha',
        },
        children: [
            {
                path: '',
                component: RedefinirSenhaView,
            },
        ],
    },
    {
        path: '/facaparte',
        component: Layout,
        meta: {
          title: 'Seja sócio',
        },
        children: [
            {
                path: '',
                component: CadastroView,
            },
        ],
    },
    {
        path: '/noticias',
        component: Layout,
        meta: {
          title: 'Notícias',
        },
        children: [
            {
                path: '',
                component: NoticiasView,
            },
        ],
    },
    {
        path: '/noticias/:slug',
        component: Layout,
        meta: {
          title: 'Notícias',
        },
        children: [
            {
                path: '',
                component: NoticiaView,
            },
        ],
    },
    {
        path: '/promocoes',
        component: Layout,
        meta: {
          title: 'Promoções',
        },
        children: [
            {
                path: '',
                component: PromocoesView,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from) => {
  document.title = 'Clube Opus | ' + to.meta.title
})

export default router
