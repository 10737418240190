<template>
    <div class="view-bg">
        <div class="navbar-filler"></div>
        <div class="view-content">

          <h1>Redefinir senha</h1>

          <v-container class="pa-0">
            <v-row>

              <v-col v-if="step == 'checkingToken'" cols="12" lg="4" offset-lg="4" class="pa-4">
                <v-container class="pa-0">
                  <v-row justify="center">
                    <v-progress-circular indeterminate color="amber" class="loading"></v-progress-circular>
                  </v-row>
                </v-container>
              </v-col>

              <v-col v-else-if="step == 'tokenOk'" cols="12" lg="4" offset-lg="4" class="pa-4">

                <p class="text-center mb-5">Escolha a nova senha para acessar<br />o Clube Opus:</p>

                <v-text-field
                  label="Nova senha"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  variant="solo"
                  :error-messages="passwordErrors"
                  @blur="validatePassword"
                  v-model="password"
                  class="mb-2"
                ></v-text-field>

                <v-text-field
                  label="Confirmar nova senha"
                  :append-icon="showPasswordConfirmation ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPasswordConfirmation ? 'text' : 'password'"
                  @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                  variant="solo"
                  :error-messages="passwordConfirmationErrors"
                  @blur="validatePassword"
                  v-model="passwordConfirmation"
                  class="mb-2"
                ></v-text-field>

                <v-container class="pa-0">
                  <v-row justify="center">
                    <v-btn
                      v-if="!loading"
                      @click="updatePassword"
                      class="bg-amber-darken-1 mx-auto"
                      rounded
                      variant="flat"
                    >
                      Enviar
                    </v-btn>
                    <v-progress-circular v-else indeterminate color="amber" class="loading"></v-progress-circular>
                  </v-row>
                </v-container>

              </v-col>

              <v-col v-else-if="step == 'tokenError1'" cols="12" lg="4" offset-lg="4" class="pa-4">
                <p>Não foi possível verificar a sua requisição para redefinição da senha.<br />Por favor tente novamente.</p>
              </v-col>

              <v-col v-else-if="step == 'tokenError2'" cols="12" lg="4" offset-lg="4" class="pa-4">
                <p>Não foi possível atualizar sua senha.<br />Por favor tente novamente.</p>
              </v-col>

              <v-col v-else cols="12" lg="4" offset-lg="4" class="pa-4">
                <p>Sua senha foi atualizada com sucesso!</p>
              </v-col>


            </v-row>
          </v-container>



        </div>
    </div>
</template>

<script setup>
  import { inject, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';

  const axios = inject('axios');
  const backendUrl = inject('backendUrl');
  const route = useRoute();
  const password = ref('');
  const showPassword = ref(false);
  const passwordErrors = ref([]);
  const passwordConfirmation = ref('');
  const showPasswordConfirmation = ref(false);
  const loading = ref(false);
  const step = ref('checkingToken');
  const email = ref('');

  const updatePassword = () => {
    let validPassword = validatePassword();
    if(validPassword) {
      loading.value = true;
      axios.post(
        backendUrl + '/reset-password',
        {
          email: email.value,
          token: route.params.token,
          password: password.value
        }
      ).then((response) => {
        loading.value = false;
        if(response.data.success) {
          step.value = 'complete';
        } else {
          step.value = 'tokenError2';
        }
      });
    }
  };

  const validatePassword = () => {
    passwordErrors.value = [];
    let pw1 = password.value?.trim() ?? '';
    let pw2 = passwordConfirmation.value?.trim() ?? '';
    if(pw1.length == 0 && pw2.length == 0) {
      passwordErrors.value.push('A senha é obrigatória');
      return false;
    }
    if(pw1 != pw2) {
      passwordErrors.value.push('As senhas não coincidem');
      return false;
    }
    if(pw1.length < 6) {
      passwordErrors.value.push('A senha precisa ter ao menos 6 caracteres');
      return false;
    }
    return true;
  };

  onMounted(() => {
    axios.get(backendUrl + '/check-reset-password-token/' + route.params.token).then((response) => {
      loading.value = false;
      if(response.data.success) {
        step.value = 'tokenOk';
        email.value = response.data.email;
      } else {
        step.value = 'tokenError1';
      }
    });
  })

</script>