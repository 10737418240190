<template>
    <footer class="footer">
        <div class="flex-row-between">
            <div class="footer-wrapper">
              <img :src="ClubeQueDaShow" alt="O Clubre de Vantagens que dá show" />    
            </div>
            <div class="contato flex-column">
              <div class="flex-row-align-baseline">
                  <h5 class="text-amber-darken-1">Fale Conosco</h5> 
              </div>
              <p>Dúvidas e sugestões<br /><span>sac@opusentretenimento.com</span></p>
              <p>Cancelamentos e pagamentos<br /><span>atendimentoclubeopus@opusentretenimento.com</span></p>
              <p>Horário de atendimento<br /><span>segunda a sexta-feira, das 9h30 às 18h</span></p>
              <p>Prazo para retorno<br /><span>até 48h</span></p>
          </div>
        </div>
        <div class="flex-row-between2">
            <a href="https://www.facebook.com/opusentretenimento/?locale=pt_BR" target="_blank" class="icon-link">
                <v-icon class="icons mx-5">
                    mdi-facebook
                </v-icon>
            </a>
            <a href="https://www.instagram.com/opusentretenimento/" target="_blank" class="icon-link">
                <v-icon class="icons mx-5">
                    mdi-instagram
                </v-icon>
            </a>
        </div>
        <hr>
        <div class="flex-row-between">
            <p class="termos" @click="rulesDialog = true">Termos de uso</p>
            <p>Copyright © OPUS ENTRETENIMENTO</p>
        </div>
    </footer>

    <Rules v-model="rulesDialog" @close-dialog="closeRulesDialog" />



</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ClubeQueDaShow from '@/assets/ClubeQueDaShow.png';
import Rules from '@/components/Rules.vue';


//mostra regulamento
const rulesDialog = ref(false);

//mostra modal do regulamento se veio '/termos-de-uso' na url
//ver config "modal" nos routes: src/router/index.js
const router = useRouter();
const route = useRoute();
const closeRulesDialog = () => {
    rulesDialog.value = false;
    if(route.params.modal != undefined && route.params.modal == 'termos-de-uso') {
        router.push('/');
    }
}
onMounted(() => {
    if(route.params.modal != undefined && route.params.modal == 'termos-de-uso') {
        rulesDialog.value = true;
    }
})


</script>

<style scoped lang="scss">
.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #121921;
    color: rgba(255,255,255,.7);
    font-size: 14px;
    padding: 2% 8%;
    .flex-row-between {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .flex-row-between2 {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        a {
            color: white;
        }
    }
    .flex-row-align-baseline {
        display: flex;
        align-items: baseline;
        flex-wrap: nowrap;
    }
}
.flex-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.footer-wrapper {
    display: flex;
    align-items: center;
    .p {
        max-width: 530px;
        text-align: left;
        margin-bottom: 25px;
    }
    img {
      max-width: 100%;
    }
    @media (max-width: 767px) {
      img {
        margin-left:15%;
        margin-top: 20px;
        max-width: 70%;
      }
    }

}
hr {
    border-color: #ededed;
    margin: 30px 0 30px 0;
    opacity: .1;
}
.contato {
    display: flex;
    justify-content: flex-start;
    color: white;
    margin-top: 45px;
    text-align: left;
    h5 {
        font-size: 20px;
        margin-bottom: 15px
    }
    p {
      color: white;
      margin: 5px 0;
      text-align: left;
    }
    span {
      color: #03af9a;
    }
    @media (max-width: 767px) {
        margin-top: 55px;
    }
}
.termos {
    margin-bottom: 10px;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
        color: white;
    }
}
@media (max-width: 767px) {
    .footer {
        padding: 3%;
    }
    .footer p {
        font-size:12px;
    }
}

</style>
