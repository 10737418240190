<template>
    <div class="slider-eventos-container">
        <h2>EVENTOS COM DESCONTO</h2>
        <div class="slider-eventos">
            <v-icon class="arrow-icon" @click="slideLeft">mdi-chevron-left</v-icon>
            <div class="cards-wrapper" ref="cardsWrapper">

                <CardEvento
                    v-for="(evento, index) of eventos" :key="index"
                    :id="evento.id"
                    :image="evento.image2"
                    :title="evento.title"
                    :date="evento.full_dates"
                    :place="evento.place_name"
                    :discount="evento.discount"
                    :url="evento.url"
                />

                <v-col cols="12" md="6" lg="4" align-self="center">
                    <div class="text-center">
                        <Button
                            buttonText="veja mais"
                            @click="router.push('/agenda')"
                        />
                    </div>
                </v-col>


            </div>
            <v-icon class="arrow-icon" @click="slideRight">mdi-chevron-right</v-icon>
        </div>
    </div>
</template>

<script setup>
import { inject, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import Button from './Button.vue';
import CardEvento from './CardEvento.vue';

const axios = inject('axios');
const backendUrl = inject('backendUrl');
const router = useRouter();
const eventos = ref([]);
const cardsWrapper = ref(null); // Reference for the cards wrapper element
const visibleCards = ref(3); // Number of cards visible at a time
const cardWidth = 500; // Width of each card in pixels

onMounted(() => {

    axios.post(
      backendUrl + '/events',
      {limit: 8},
    ).then((response) => {
      eventos.value = response.data;
    });
})

const slideLeft = () => {
    const wrapper = cardsWrapper.value; // Accessing the value of the reference
    wrapper.scrollLeft -= wrapper.offsetWidth;
}

const slideRight = () => {
    const wrapper = cardsWrapper.value; // Accessing the value of the reference
    wrapper.scrollLeft += wrapper.offsetWidth;
}
</script>

<style scoped lang="scss">
.slider-eventos-container {
    display: flex;
    flex-direction: column;
    background-color: #e8e4e1;
    h2 {
        margin: 16px 0;
        align-self: center;
    }
}
.slider-eventos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
    position: relative;
}

.arrow-icon {
    font-size: 48px;
    color: $verde-opus;
    cursor: pointer;
    padding: 0px;
}

.cards-wrapper {
    display: flex;
    overflow-x: hidden;
    width: 100%;
    /*min-width: 335px;*/
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
}

.card-eventos {
    scroll-snap-align: start;
    flex-shrink: 0;
}

</style>
