<template lang="html">
  <div class="cadastro">

    <div class="flex-row">

      <v-text-field
        label="Nome Completo"
        variant="outlined"
        :error-messages="nameErrors"
        @blur="validateName"
        v-model="name"
        class="mb-2"
      ></v-text-field>

      <v-text-field
        label="Email"
        variant="outlined"
        :error-messages="emailErrors"
        @blur="validateEmail"
        v-model="email"
        class="mb-2"
      ></v-text-field>

    </div>

    <div class="flex-row">
        
      <v-text-field
        label="Data de nascimento"
        variant="outlined"
        :error-messages="birthDateErrors"
        @blur="validateBirthDate"
        v-model="birthDate"
        v-maska:[dateMask]
        class="mb-2"
      ></v-text-field>
      
      <v-select
        label="Sexo"
        :items="[{value:'F', title:'Feminino'}, {value:'M', title:'Masculino'}]"
        variant="outlined"
        :error-messages="genderErrors"
        @blur="validateGender"
        v-model="gender"
        class="mb-2"
      ></v-select>
    
    </div>
    
    <div class="flex-row">

      <v-text-field
        label="CPF"
        variant="outlined"
        :error-messages="cpfErrors"
        @blur="validateCPF"
        v-model="cpf"
        v-maska:[cpf_mask]
        class="mb-2"
        :disabled="action == 'update'"
      ></v-text-field>

      <v-text-field
        label="Celular com ddd"
        variant="outlined"
        :error-messages="phoneErrors"
        @blur="validatePhone"
        v-model="phone"
        v-maska:[phone_mask]
        class="mb-2"
      ></v-text-field>

    </div>
    
    <div class="flex-row">

      <v-text-field
        :label="action == 'create' ? 'Senha de acesso ao Clube' : 'Definir nova senha'"
        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        variant="outlined"
        :error-messages="passwordErrors"
        @blur="validatePassword"
        v-model="password"
        class="mb-2"
      ></v-text-field>

      <v-text-field
        label="Confirmar senha"
        :append-icon="showPasswordConfirmation ? 'mdi-eye-off' : 'mdi-eye'"
        :type="showPasswordConfirmation ? 'text' : 'password'"
        @click:append="showPasswordConfirmation = !showPasswordConfirmation"
        variant="outlined"
        :error-messages="passwordErrors"
        @blur="validatePassword"
        v-model="passwordConfirmation"
        class="mb-2"
      ></v-text-field>

    </div>

    <div class="d-flex">

      <v-btn
        v-if="action == 'update'"
        @click="closeDialog"
        class="bg-amber-darken-1 mr-auto"
        rounded
        variant="flat"
      >
        Fechar
      </v-btn>

      <v-btn
        @click="sendForm"
        :disabled="isSending"
        class="bg-amber-darken-1 ml-auto"
        rounded
        variant="flat"
      >
        <v-progress-circular v-if="isSending" indeterminate color="black" class="align-self-center mr-2"></v-progress-circular>    
        {{ action == 'create' ? 'Próximo' : 'Atualizar' }}
      </v-btn>

    </div>

  </div>
</template>

<script setup>
import { inject, ref, watch } from 'vue';
import { vMaska } from "maska";

const axios = inject('axios');
const backendUrl = inject('backendUrl');
const props = defineProps({
  action: {
    type: String,
    default: 'create',
  },
  dados: {
    type: Object,
    default: {
      name: null,
      email: null,
      birth_date: null,
      gender: null,
      cpf: null,
      phone: null
    },
  },
});
const emit = defineEmits(["send-form", "close-dialog"]);
const isSending = ref(false);

//fecha dialog
const closeDialog = () => {
  emit("close-dialog");
}

//retorna os dados do form para o parent
const sendForm = async () => {
  isSending.value = true;
  let validName = validateName();
  let validEmail = await validateEmail();
  let validBirthDate = validateBirthDate();
  let validGender = validateGender();
  let validCpf = await validateCPF();
  let validPhone = validatePhone();
  let validPassword = validatePassword();
  if(
    !validName ||
    !validEmail || 
    !validBirthDate ||
    !validGender ||
    !validPhone ||
    !validCpf ||
    !validPassword
  ) {
    isSending.value = false;
    return false;
  }
  emit(
    "send-form",
    {
      name: name.value,
      email: email.value,
      birth_date: birthDate.value,
      gender: gender.value,
      cpf: cpf.value.replace(/\D/g,''),
      phone: phone.value.replace(/\D/g,''),
      password: password.value,
    }
  );
};

//nome
const name = ref(props.dados.name);
const nameErrors = ref([]);
const validateName = () => {
  nameErrors.value = [];
  if(name.value === null || name.value.trim() == '') {
    nameErrors.value.push('O nome é obrigatório');
    return false;
  }
  return true;
};

//email
const email = ref(props.dados.email);
const emailErrors = ref([]);
const validateEmail = async () => {
  emailErrors.value = [];
  //string é um email válido?
  if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
    emailErrors.value.push('O email é inválido');
    return false;
  }
  //email já existe na base?
  let response = null;
  if(props.action == 'create') {
    //se cliente está se cadastrando, verifica contra todos os emails da base
    response = await axios.get(backendUrl + '/check-email/' + email.value);
  } else {
    //se está atualizando dados, verifica contra todos os emails da base menos o seu
    response = await axios.get(backendUrl + '/customer/check-email/' + email.value);
  }
  if(response.data.exists) {
    emailErrors.value.push('Esse email já está cadastrado');
    return false;
  }
  return true;
};

//data de nascimento
const birthDate = ref(props.dados.birth_date);
const dateMask = { mask: '##/##/####' };
const birthDateErrors = ref([]);
const validateBirthDate = () => {
  birthDateErrors.value = [];
  if(!checkDate(birthDate.value)) {
    birthDateErrors.value.push('Data de nascimento inválida');
    return false;
  }
  return true;
};
const checkDate = (date) => {
  if(date == null) return false;
  let day = date.split('/');
  if(day.length != 3) return false;
  day = new Date(`${day[2]}-${day[1]}-${day[0]}`);
  if(isNaN(day.getTime())) return false;
  let today = new Date();
  if(day.getTime() > today.getTime()) return false;
  return true;
}

//gender
const gender = ref(props.dados.gender);
const genderErrors = ref([]);
const validateGender = () => {
  genderErrors.value = [];
  if(gender.value === null || gender.value.trim() == '') {
    genderErrors.value.push('O sexo é obrigatório');
    return false;
  }
  return true;
};

//cpf
const cpf = ref(props.dados.cpf);
const cpf_mask = { mask: '######### ##' };
const cpfErrors = ref([]);
const validateCPF = async () => {
  if(props.action == 'update') {
    //cpf não pode ser alterado ao editar dados cadastrais
    return true;
  }
  cpfErrors.value = [];
  //string é um cpf válido?
  if(!vCPF(cpf.value)) {
    cpfErrors.value.push('O cpf é inválido');
    return false;
  }
  //cpf já existe na base?
  let response = await axios.get(backendUrl + '/check-cpf/' + cpf.value);
  if(response.data.exists) {
    cpfErrors.value.push('Esse cpf já está cadastrado');
    return false;
  }
  return true;
};
const vCPF = (cpf) => {
  if(cpf == null) return false;
	cpf = cpf.replace(/[^\d]+/g,'');
	if(cpf == '') return false;	
	// Elimina CPFs invalidos conhecidos	
	if (cpf.length != 11 || 
		cpf == "00000000000" || 
		cpf == "11111111111" || 
		cpf == "22222222222" || 
		cpf == "33333333333" || 
		cpf == "44444444444" || 
		cpf == "55555555555" || 
		cpf == "66666666666" || 
		cpf == "77777777777" || 
		cpf == "88888888888" || 
		cpf == "99999999999")
			return false;		
	// Valida 1o digito	
	let add = 0;	
	for (let i=0; i < 9; i ++)		
		add += parseInt(cpf.charAt(i)) * (10 - i);	
		let rev = 11 - (add % 11);	
		if (rev == 10 || rev == 11)		
			rev = 0;	
		if (rev != parseInt(cpf.charAt(9)))		
			return false;		
	// Valida 2o digito	
	add = 0;	
	for (let i = 0; i < 10; i ++)		
		add += parseInt(cpf.charAt(i)) * (11 - i);	
	rev = 11 - (add % 11);	
	if (rev == 10 || rev == 11)	
		rev = 0;	
	if (rev != parseInt(cpf.charAt(10)))
		return false;		
	return true;   
}

//fone
const phone = ref(props.dados.phone);
const phone_mask = { mask: '(##) ###.###.###' };
const phoneErrors = ref([]);
const validatePhone = () => {
  phoneErrors.value = [];
  if(phone.value === null || phone.value.trim() == '') {
    phoneErrors.value.push('O celular é obrigatório');
    return false;
  }
  return true;
};

//senha
const password = ref('');
const showPassword = ref(false);
const passwordErrors = ref([]);
const passwordConfirmation = ref('');
const showPasswordConfirmation = ref(false);
const validatePassword = () => {
  passwordErrors.value = [];
  let pw1 = password.value?.trim() ?? '';
  let pw2 = passwordConfirmation.value?.trim() ?? '';
  if(props.action == 'update') {
    if(pw1.length == 0 && pw2.length == 0) {
      return true;
    }
  } else {
    if(pw1.length == 0 && pw2.length == 0) {
      passwordErrors.value.push('A senha é obrigatória');
      return false;
    }
  }
  if(pw1 != pw2) {
    passwordErrors.value.push('As senhas não coincidem');
    return false;
  }
  if(pw1.length < 6) {
    passwordErrors.value.push('A senha precisa ter ao menos 6 caracteres');
    return false;
  }
  return true;
};
</script>

<style lang="scss" scoped>
.cadastro {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  .flex-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0 26px;
    > * {
        min-width: 300px;
        max-width: 608px;
    }
  }
  h2 {
    margin-bottom:20px
  }
  .botao {
    align-self: flex-end;
  }
}
</style>
