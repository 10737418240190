<template>
  
  <h1>Notícias</h1>

  <v-container class="pa-0">
    <v-row>
      <v-col cols="12">

        <img class="w-100 rounded-lg mb-4" v-if="imagem !== false" :src="imagem" />      

        <v-sheet id="post-body" class="bg-white rounded-lg pa-5">

          <h2 class="text-left mb-4">{{ noticia.title }}</h2>

          <p class="caption">{{ noticia.updated }}</p>

          <v-divider></v-divider>

          <div class="texto my-5" v-html="noticia.text"></div>

          <h2 v-if="gallery.length" class="text-left my-4">Galeria de fotos</h2>

          <vue-picture-swipe v-if="gallery.length" :items="gallery"></vue-picture-swipe>

        </v-sheet>

      </v-col>
    </v-row>
  </v-container>



</template>

<script setup>
  import { inject, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useDisplay } from 'vuetify';
  import VuePictureSwipe from 'vue-picture-swipe';

  const axios = inject('axios');
  const backendUrl = inject('backendUrl');
  const noticia = ref([]);
  const route = useRoute();
  const { xs } = useDisplay();
  const imagem = ref(false);
  const gallery = ref({});

  onMounted(() => {
    axios.get(backendUrl + '/posts/' + route.params.slug).then((response) => {
      noticia.value = response.data;
      imagem.value = xs.value?response.data.image2:response.data.image1;
      gallery.value = response.data.gallery;
      console.log(gallery.value);
    });
  })
</script>

<style lang="scss" scoped>
  
  #post-body:deep(ol), #post-body:deep(ul) {
    color: gray;
    padding: 10px 30px;
  }


</style>
