<template>
<div class="loginForm">
    <div v-if="action == 'login'" class="flex-wrapper">
        <img :src="logoSvg" alt="Opus Logo" />
        <input class="input" type="email" name="email" id="email" placeholder="email" v-model="email">
        <input class="input" type="password" name="password" id="password" placeholder="senha" v-model="password">
        <v-btn
            v-if="!loading"
            @click="doLogin"
            class="bg-amber-darken-1"
            rounded
            variant="flat"
        >
            Entrar
        </v-btn>
        <v-progress-circular v-else indeterminate color="amber" class="loading"></v-progress-circular>
        <span class="login-error" v-if="loginError != false">{{ loginError }}</span>
        <a @click="action = 'resetPassword1'" class="textLink">Esqueceu sua senha?</a>
        <a href="/facaparte">Seja sócio</a>
    </div>
    <div v-else-if="action == 'resetPassword1'" class="flex-wrapper">
        <img :src="logoSvg" alt="Opus Logo" />
        <p class="text-black text-center">Um link para redefinir a sua senha será<br />enviado para o email utilizado no cadastro</p>
        <input class="input" type="email" name="email" id="email" placeholder="email" v-model="emailResetPassword">
        <v-btn
            v-if="!loading"
            @click="resetPassword"
            class="bg-amber-darken-1"
            rounded
            variant="flat"
        >
            Enviar
        </v-btn>
        <v-progress-circular v-else indeterminate color="amber" class="loading"></v-progress-circular>
    </div>
    <div v-else class="flex-wrapper">
        <img :src="logoSvg" alt="Opus Logo" />
        <p class="text-black text-center">Uma mensagem foi enviada para<br /><span class="text-white">{{ emailResetPassword }}</span><br />com o link para redefinir a sua senha</p>
        <v-btn
            @click="emit('closeLoginDialog')"
            class="bg-amber-darken-1"
            rounded
            variant="flat"
        >
            Fechar
        </v-btn>
    </div>
</div>
</template>

<script setup>
import {
    inject,
    onMounted,
    ref,
    watch
} from 'vue';
import {
    useStore
} from '@/stores';
import logoSvg from '@/assets/whiteLogo.svg';
import {
    useRoute,
    useRouter
} from 'vue-router';

const route = useRoute();
const router = useRouter();
const emit = defineEmits(['closeLoginDialog']);
const store = useStore();
const axios = inject('axios');
const backendUrl = inject('backendUrl');
const csrfUrl = inject('csrfUrl');
const action = ref('login');
const email = ref('');
const password = ref('');
const emailResetPassword = ref('');
const loginError = ref(false);
const loading = ref(false);

const doLogin = () => {
    loginError.value = false;
    axios.get(csrfUrl).then(response => {
        loading.value = true;
        axios.post(
            backendUrl + '/customer-login', {
                email: email.value,
                password: password.value,
            }
        ).then((response) => {
            if (response.data.success) {
                //cliente logou ok, redireciona para tela de perfil
                store.storeCustomer(response.data.customer);
                loading.value = false;
                emit('closeLoginDialog');
                if (route.path == '/perfil') {
                    location.reload();
                } else {
                    router.push('/perfil');
                }
            } else {
                loading.value = false;
                loginError.value = response.data.error;
                store.logout();
            }
        }).catch((error) => {
            loading.value = false;
            if (error.response.data.message) {
                loginError.value = error.response.data.message;
            } else {
                loginError.value = 'Usuário não encontrado!'; //?
            }
        });
    });

}

const resetPassword = () => {
  loading.value = true;
  axios({
    method: 'post',
    url: backendUrl + '/request-password-reset',
    withCredentials: false,
    data: {email: emailResetPassword.value},
  }).then(function (response) {
    loading.value = false;
    if(response.data.success) {
        action.value = 'resetPassword2';
    } else {
        emailResetPassword.value = '';
        alert(response.data.error);
    }
  }).catch(function (error) {
    loading.value = false;
    //...
  });

  /*
  axios.post(
    backendUrl + '/request-password-reset/',
    {email: emailResetPassword.value}
  ).then((response) => {
    loading.value = false;
    if(response.data.success) {
      action.value = 'resetPassword2';
    } else {
      alert(response.data.error);
    }
  }).catch((error) => {
    loading.value = false;
    //...
  });
  */
}



/*
const resetPassword = () => {
  loading.value = true;
  axios.get(
    backendUrl + '/request-password-reset/' + emailResetPassword.value
  ).then((response) => {
    loading.value = false;
    if(response.data.success) {
      action.value = 'resetPassword2';
    } else {
      alert(response.data.error);
    }
  }).catch((error) => {
    loading.value = false;
    //...
  });
}
*/
onMounted(() => {
    document.addEventListener("keydown", (e) => {
        if (e.key == 'Escape') {
            //document.getElementById("loginClose").click();
            emit('closeLoginDialog')
        }
    });
})

watch(loginError, (value) => {
    if (value != false) {
        setTimeout(() => {
            loginError.value = false;
        }, 2000);
    }
})
</script>

<style scoped lang="scss">
.loginForm {
    background-color: $verde-opus;
    border-radius: 10px;
    box-shadow: 0 1px 2px -1px rgba(0,0,0,0.3);
    .flex-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        padding: 16px;
    }
    img {
        width: 250px;
        // align-self: center;
    }
    .loading {
        align-self: center;
    }
    a {
        color: white;
        font-size: 13px;
    }
    .input {
        border-radius: 5px;
        padding: 5px;
        margin: 0 6px;
        background-color: #FFF;
    }
}

.login-error {
    width: 100%;
    display: block;
    clear: both;
    background-color: rgba(0,0,0,0.5);
    color: white;
    text-align: center;
    font-size: 12px;
    margin: 10px 0;
    padding: 10px;
    border-radius: 1rem;
}

.textLink {
  cursor:pointer;
  text-decoration:underline;
}
</style>