<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script setup>
</script>

<style lang="scss">
  * {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    font-weight: 600;
  }
  body {
    margin: 0
  }
  .navbar-filler {
    min-height: 80px;
  }
  .view-bg {
    background-color: $verde-opus;
  }
  .view-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 80px 8% 150px 8%;
    background-color: #e8e4e1;
    min-height:500px;
    h1 {
      margin-bottom: 20px;
      font-size: 32px;
      text-align:left;
    }
  }
  p {
    color: rgb(119, 119, 119);
    font-size: 16px;
    margin-bottom: 10px;
  }
  .gap-5px {
    gap: 5px;
  }
  .gap-10px {
    gap: 10px;
  }
  @media (max-width: 767px) {
    .view-content {
      padding: 30px 3% 50px 3%;
      h1 {
        margin-bottom: 10px;
      }
    }
  }

</style>