<template lang="html">
    <div class="faq">
        <h2>DÚVIDAS FREQUENTES</h2>
        <v-expansion-panels>
            <v-expansion-panel
                v-for="i in faq"
                :title="i.question"
                class="painel"
            >
                <v-expansion-panel-text>
                    <div v-html='i.answer'></div>
                </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
    </div>
</template>

<script setup>
    import { inject, onMounted, ref } from 'vue';

    const axios = inject('axios');
    const backendUrl = inject('backendUrl');
    const faq = ref([]);

    onMounted(() => {
        axios.get(backendUrl + '/faq').then((response) => {
            faq.value = response.data;
        });
    })

</script>

<style lang="scss" scoped>
.faq {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: $bg-color;
    padding: 5% 15%;
    @media (max-width: 767px) {
        padding: 5%;
    }
    h2 {
        margin-bottom: 25px;
        color: $verde-opus;
    }
    .painel {
        margin: 5px 0;
    }
}
</style>
